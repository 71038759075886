import {atom, selector} from 'recoil';
import uniqueId from 'lodash/uniqueId';
import {productColumnHeaders} from '../constants/productColumnHeaders';

const productsListDefaultAtom = atom({
  key: "productsListDefault",
  default: {}
});

const productsHeadersDefaultAtom = atom({
  key: "productsHeadersDefault",
  default: productColumnHeaders
});

const productsHeadersAtom = atom({
  key: "productsHeaders",
  default: productsHeadersDefaultAtom
});

const visibleColumnsState = atom({
    key: 'visibleColumns',
    default: selector({
      key: 'visibleColumnsSelector',
      get: ({get}) => {
        return get(productsHeadersAtom).filter(column => column.visible === true)
      }
    })
  }
);

const invisibleColumnsState = atom({
    key: 'invisibleColumns',
    default: selector({
      key: 'invisibleColumnsSelector',
      get: ({get}) => {
        return get(productsHeadersAtom).filter(column => column.visible === false)
      }
    })
  }
);

const visibleColumnsDefaultState = atom({
    key: 'visibleDefaultColumns',
    default: selector({
      key: 'visibleDefaultColumnsSelector',
      get: ({get}) => {
        return get(productsHeadersDefaultAtom).filter(column => column.visible === true)
      }
    })
  }
);

const invisibleColumnsDefaultState = atom({
    key: 'invisibleDefaultColumns',
    default: selector({
      key: 'invisibleColumnsDefaultSelector',
      get: ({get}) => {
        return get(productsHeadersDefaultAtom).filter(column => column.visible === false)
      }
    })
  }
);

const columnsViewAtom = atom({
  key: "columnsView",
  default: selector({
    key: 'columnsViewSelector',
    get: ({get}) => {
      const visibleItems = get(visibleColumnsState).map(({fieldName}) => ({id: uniqueId(), content: fieldName}));
      const invisibleItems = get(invisibleColumnsState).map(({fieldName}) => ({id: uniqueId(), content: fieldName}));

      return {
        [uniqueId()]: {
          name: "Available",
          items: invisibleItems
        },
        [uniqueId()]: {
          name: "Displayed",
          items: visibleItems
        }
      };
    }
  })
});

const columnsViewDefaultAtom = atom({
  key: "columnsDefaultView",
  default: selector({
    key: 'columnsDefaultViewSelector',
    get: ({get}) => {
      const visibleItems = get(visibleColumnsDefaultState).map(({fieldName}) => ({id: uniqueId(), content: fieldName}));
      const invisibleItems = get(invisibleColumnsDefaultState).map(({fieldName}) => ({
        id: uniqueId(),
        content: fieldName
      }));

      return {
        [uniqueId()]: {
          name: "Available",
          items: invisibleItems
        },
        [uniqueId()]: {
          name: "Displayed",
          items: visibleItems
        }
      };
    }
  })
});

const productsListAtom = atom({
  key: "productsList",
  default: productsListDefaultAtom
});

const priceHistoryAtom = atom({
  key: "priceHistory",
  default: []
})

const filterStateAtom = atom({
  key: "filterState",
  default: [{id: +uniqueId(), column: "", operator: "", value: "", dataType: "number"}]
})

const filtersCountState = selector({
  key: "filtersCountSelector",
  get: ({get}) => {
    return get(filterStateAtom).filter(item => item.value&&item.operator).length;
  }
})

const selectedLayoutAtom = atom({
  key: "selectedLayout",
  default: 0
})

const oneProductAtom = atom({
  key: "oneProduct",
  default: {}
})

const orderStateAtom = atom({
  key: "orderState",
  default: 'asc'
});

const sortFieldAtom = atom({
  key: "sortField",
  default: 'id'
})

const rowsPerPageAtom = atom({
  key: "rowsPerPage",
  default: 25
})

const transactionLogsAtom = atom({
  key: "transactionLogs",
  default: []
})

const showShippingAtom = atom({
  key: "showShipping",
  default: false
})

export {
  productsListDefaultAtom,
  productsListAtom,
  productsHeadersAtom,
  productsHeadersDefaultAtom,
  visibleColumnsDefaultState,
  invisibleColumnsDefaultState,
  visibleColumnsState,
  invisibleColumnsState,
  columnsViewAtom,
  columnsViewDefaultAtom,
  priceHistoryAtom,
  filterStateAtom,
  filtersCountState,
  oneProductAtom,
  orderStateAtom,
  sortFieldAtom,
  rowsPerPageAtom,
  selectedLayoutAtom,
  transactionLogsAtom,
  showShippingAtom
}
