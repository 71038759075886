import {styled} from '@mui/styles';
import TableRow from '@mui/material/TableRow';

export const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ece9e9'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#fff'
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&.Mui-selected:hover, &.Mui-selected': {
    backgroundColor: "#d5e7d5",
  }
}));
