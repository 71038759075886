import React, {useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {toast, Toaster} from 'react-hot-toast';
import Spinner from '../../components/Spinner';
import {useRecoilState} from 'recoil';
import {authAtom} from '../../state';
import {login} from '../../services';
import {Checkbox, FormControlLabel, Grid} from '@mui/material';
import ForgotPassword from '../../components/ForgotPassword';
import {Redirect} from 'react-router-dom';
import PSLogo from '../../assets/images/ASER-Signature-rgb.png';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="primary" href="https://www.applicateservices.com/" target="_blank">
        Applicate Services
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#53A551",
    }
  },
  typography: {
    fontFamily: [
      'open sans',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "#fff"
        },
      },
    },
  },
})

export default function SignIn() {
  const [auth, setAuth] = useRecoilState(authAtom);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const user = new FormData(event.currentTarget);
      setLoading(true);
      const response = await login({
        email: user.get("email"),
        password: user.get("password"),
        rememberMe: user.get("remember") === "remember"
      })
      const {data} = response.data;
      localStorage.setItem("user", JSON.stringify(data));
      setAuth(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.error);
    }
  }

  if (loading) return <Spinner />
  if (error) toast.error(error);

  if (auth) return <Redirect to="/" />

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="img" alt="Your logo" src={PSLogo} sx={{width: '100%', mb: 3}}/>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              type="email"
              label="Email Address"
              name="email"
              autoComplete="nope"
              autoFocus
            />
            <input type="password" name="fake-password" autoComplete="new-password" tabIndex="-1"
                   style={{opacity: 0, float: "left", border: "none", height: "0", width: "0"}} />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" name="remember" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <ForgotPassword />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}} />
      </Container>
      <Toaster />
    </ThemeProvider>
  )
}
