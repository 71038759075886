import React from 'react';
import {useFetchData} from '../../hooks';
import {productsListDefaultAtom} from '../../state';
import Spinner from '../../components/Spinner';
import NotFound from '../NotFound';
import ProductsToolbar from '../../components/ProductsToolbar';
import ProductsList from '../../components/ProductsList';
import Paper from '@mui/material/Paper';
import {getAll} from '../../services';

export default function Products() {
  const {isLoading, isError, errorMessage} = useFetchData(getAll, productsListDefaultAtom);

  if (isLoading) return <Spinner />;
  if (isError) return <NotFound error={errorMessage}/>;

  return (
    <Paper elevation={12} sx={{display: "flex", flexDirection: "column", padding: 1, minHeight: "97%"}}>
      <ProductsToolbar />
      <ProductsList />
    </Paper>
  )
}
