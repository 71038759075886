import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import PSLogo from '../../assets/images/ASER-Signature-rgb.png';

export default function Logo() {
  return (
    <Link href="/#">
      <Box component="img" alt="Your logo" src={PSLogo} sx={{height: "60px", padding: "15px"}} />
    </Link>
  );
}
