import React, {useState} from 'react';
import {Grid, MenuItem, TextField} from '@mui/material';
import DeleteButton from '../DeleteButton';
import {numberOperators, stringOperators} from '../../constants/operators';
import PropTypes from 'prop-types';
import {useGetFilterColumns} from '../../hooks';
import {supplierStatus} from '../../constants/supplierStatus';

function capitalizeFirstLetter(string) {
  const arr = string.split(' ');
  const result = arr.map(item => item.charAt(0).toUpperCase() + item.slice(1));
  return result.join(' ');
}

export default function MultiFilterItem({id, row, length, setFilter, filterArr}) {
  const {dataType, column, operator, value} = row;
  const [selectedColumn, setSelectedColumn] = useState(column);
  const [selectedOperator, setSelectedOperator] = useState(operator);
  const [selectedStatus, setSelectedStatus] = useState(capitalizeFirstLetter(value.replace('_', ' ')));
  const [inputValue, setInputValue] = useState(value);
  const filteredColumns = [...useGetFilterColumns(), {
    "field": "status",
    "fieldName": "Competitor url status",
    "visible": false,
    "type": "string"
  }];

  const filterSelectColumns = filteredColumns.map((option) => {
      return (
        <MenuItem
          key={option.fieldName}
          value={option.fieldName}
          disabled={filterArr.some(filter => filter.column === option.fieldName)}
        >
          {option.fieldName}
        </MenuItem>
      )
    }
  )

  const filterSelectOperators = (dataType === "number" ? numberOperators : stringOperators).map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    )
  );

  const statusSelect = Object.keys(supplierStatus).map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    )
  );

  const handleColumnsChange = (event) => {
    const columnValue = event.target.value;
    setSelectedColumn(columnValue);
    if (columnValue === 'Competitor url status') {
      setSelectedOperator('equals');
      setFilter(items => items.map((row) => {
        return row.id === id ? {...row, operator: 'equals'} : row;
      }));
    }
    const {type} = filteredColumns.find(item => item.fieldName === columnValue);
    setFilter(items => items.map((row) => {
      return row.id === id ? {...row, column: columnValue, dataType: type} : row;
    }));
  };

  const handleOperatorsChange = (event) => {
    setSelectedOperator(event.target.value)
    setFilter(items => items.map((row) => {
      return row.id === id ? {...row, operator: event.target.value} : row;
    }));
  }

  const handleInputChange = (event) => {
    const currentValue = event.target.value;

    if (!selectedColumn) return null;
    if (selectedColumn === "SKU" && currentValue.includes(',')) {
      setSelectedOperator('equals');
      setFilter(items => items.map((row) => {
        return row.id === id ? {...row, operator: 'equals'} : row;
      }));
    }
    if (dataType === 'number') {
      if (currentValue.match(/^-?(\d+)?(\.)?(\d+)?$/)) {
        setInputValue(currentValue);
        setFilter(items => items.map((row) => {
          return row.id === id ? {...row, value: currentValue.trim()} : row;
        }));
      }
    } else {
      setInputValue(currentValue);
      setFilter(items => items.map((row) => {
        return row.id === id ? {...row, value: currentValue.trim()} : row;
      }));
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setFilter(items => items.map((row) => {
      return row.id === id ? {...row, value: supplierStatus[event.target.value]} : row;
    }));

  }

  const handleDeleteFilter = () => {
    if (length === 1) return null;
    setFilter(items => items.filter(item => item.id !== id));
  }

  return (
    <>
      <Grid item xs={5}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              transitionDuration: 0,
            },
          }}
          label="columns"
          value={selectedColumn}
          onChange={handleColumnsChange}
          variant="standard"
          fullWidth
        >
          {filterSelectColumns}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              transitionDuration: 0,
            },
          }}
          disabled={selectedColumn === 'Competitor url status' || (selectedColumn === "SKU" && inputValue.includes(','))}
          label="operators"
          value={selectedOperator}
          onChange={handleOperatorsChange}
          variant="standard"
          fullWidth
        >
          {filterSelectOperators}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        {selectedColumn !== 'Competitor url status' ?
          <TextField
            multiline
            label="value"
            variant="standard"
            value={inputValue}
            onChange={handleInputChange}
          /> :
          <TextField
            select
            SelectProps={{
              MenuProps: {
                transitionDuration: 0,
              },
            }}
            label="status"
            value={selectedStatus}
            onChange={handleStatusChange}
            variant="standard"
            fullWidth
          >
            {statusSelect}
          </TextField>
        }
      </Grid>
      <Grid item xs={1} sx={{mt: 1.5}}>
        {filterArr.length > 1 && <DeleteButton onClick={handleDeleteFilter} />}
      </Grid>
    </>
  )
}

MultiFilterItem.propTypes = {
  id: PropTypes.number,
  row: PropTypes.object,
  length: PropTypes.number,
  setFilter: PropTypes.func,
  filterArr: PropTypes.array
}
