import React from 'react';
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Paper from '@mui/material/Paper';
import {chartsColors} from '../../constants/chartsColors';
import Title from '../Title';
import PropTypes from 'prop-types';

export default function DailyChart({data}) {
  const {ps_price, product_suppliers} = data;
  const suppliersPrices = [...product_suppliers].sort((a, b) => a.id - b.id).map(({supplier_name, current_price}) => ({
    name: supplier_name,
    price: +current_price.slice(1)
  }));
  const prices = [...suppliersPrices, {name: "Paint Supply", price: +ps_price.slice(1)}];

  return (
    <Paper elevation={6} sx={{p: 2}}>
      <Title>Daily Chart</Title>
      <ResponsiveContainer width="100%" height={664}>
        <BarChart
          data={prices}
          layout="vertical"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis type="number" />
          <YAxis type="category" dataKey="name" tick={{fontSize: "12px"}} minTickGap={10} width={150}/>
          <Tooltip wrapperStyle={{zIndex: 1000}}/>
          <Bar dataKey="price" fill="#53A551">
            {prices.map((entry, index) => (
              <Cell key={index} fill={chartsColors[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  )
}

DailyChart.propTypes = {
  data: PropTypes.object
}

