import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, TableBody} from '@mui/material';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import {useFetchData} from '../../hooks';
import {getTransactionsLogs} from '../../services';
import {transactionLogsAtom} from '../../state';
import Spinner from '../Spinner';
import NotFound from '../../pages/NotFound';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {StyledBodyCell, StyledHeaderCell, StyledTableRow} from '../../styleditems';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function TransactionLogs({id}) {
  const {isLoading, isError, fetchedData} = useFetchData(getTransactionsLogs, transactionLogsAtom, id);

  if (isLoading) return <Spinner />;
  if (isError) return <NotFound />;
  if (fetchedData.length === 0) return null;

  const headers = Object.keys(fetchedData[0]).map((item) => (
    <StyledHeaderCell key={item}>
      {item.replace('_', ' ')}
    </StyledHeaderCell>
  ));

  const rows = fetchedData.map(row => (
    <StyledTableRow key={row.id}>
      {Object.keys(fetchedData[0]).map((item) => {
          if (item === 'created_at') {
            return (
              <StyledBodyCell key={item}>
                {moment(row[item]).format('MM/DD/YYYY, hh:mm:ss')}
              </StyledBodyCell>
            )
          } else {
            return (
              <StyledBodyCell key={item}>
                {row[item]}
              </StyledBodyCell>
            )
          }
        }
      )}
    </StyledTableRow>
  ))

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Transaction Logs</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

TransactionLogs.propTypes = {
  id: PropTypes.string
}
