import React, {useRef, useState} from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import {downloadCSV} from '../../services';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {toast} from 'react-hot-toast';
import {useRecoilValue} from 'recoil';
import {filterStateAtom} from '../../state';
import {getFilterData} from '../../helpers';
import MiniLoader from '../MiniLoader';

export default function ExportCSV() {
  const [transactionData, setTransactionData] = useState([])
  const [isLoading, setLoading] = useState(false);
  const filter = useRecoilValue(filterStateAtom);
  const filterData = getFilterData(filter);
  const csvLink = useRef();

  const handleDownload = async () => {
    try {
      setLoading(true)
      const response = await downloadCSV(filterData);
      setTransactionData(response.data);
      setLoading(false);
      csvLink.current.link.click()
    } catch (e) {
      toast.error("Error downloading report")
    }
  }

  return (
    <>
      <Button
        color="primary"
        disabled={isLoading}
        onClick={handleDownload}
      >
        <DownloadIcon />
        {isLoading ? 'Loading...' : 'Export CSV'}
        {isLoading && <MiniLoader />}
      </Button>
      <CSVLink
        data={transactionData}
        separator={`,`}
        filename={`report_${moment().format('MM/DD/YYYY')}.csv`}
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </>
  );
}
