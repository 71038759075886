import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import SortableCell from './SortableCell';
import PropTypes from 'prop-types';
import {StyledHeaderCell} from '../../styleditems';
import {useRecoilValue} from 'recoil';
import {showShippingAtom} from '../../state';

export default function ProductsTableHead({columns, onLoading, competitors}) {
  const showPricing = useRecoilValue(showShippingAtom);
  const tableRows = columns.map(column => {
    if (column.field === 'product_suppliers') {
      return (
        <>
          <SortableCell key={column.fieldName} data={column} onLoading={onLoading} competitors={competitors} />
          {showPricing &&
            (column.slug.includes('amazon') || column.slug.includes('walmart')) &&
            <SortableCell key={`${column.fieldName} name`} data={column} onLoading={onLoading} shippingCost={`${column.fieldName} Seller Name`}/>}
          {showPricing && <SortableCell key={`${column.fieldName} price`} data={column} onLoading={onLoading}
                                        shippingCost='Product Price' />}
          {showPricing && <SortableCell key={`${column.fieldName} shipping`} data={column} onLoading={onLoading}
                                        shippingCost='Shipping Fee' />}
          {showPricing &&
            !column.slug.includes('amazon') &&
            !column.slug.includes('walmart') &&
            <SortableCell key={`${column.fieldName} policy`} data={column} onLoading={onLoading}
                          shippingCost='Shipping Policy' />
          }
        </>
      )
    }
    if (column.field === 'updated_at') {
      return (
        <StyledHeaderCell key={column.fieldName}>
          {column.fieldName}
        </StyledHeaderCell>
      )
    }
    if (column.field === 'ps_price') {
      return (
        <>
          <SortableCell key={column.fieldName} data={column} onLoading={onLoading} />
          <SortableCell key={`${column.fieldName} shipping`} data={column} onLoading={onLoading}
                        shippingCost='Shipping Fee' />
        </>
      )
    }
    return (
      <SortableCell key={column.fieldName ? column.fieldName : 'actions'} data={column} onLoading={onLoading} />)
  });

  return (
    <TableHead>
      <TableRow>
        {tableRows}
      </TableRow>
    </TableHead>
  );
}

ProductsTableHead.propTypes = {
  columns: PropTypes.array
}


