import React, {useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import {useOpenCloseDialog} from '../../hooks';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField
} from '@mui/material';
import CustomDialogTitle from '../CustomDialogTitle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {editCompetitorURL} from '../../services';
import {oneProductAtom} from '../../state';
import {useRecoilState} from 'recoil';
import {supplierStatus} from '../../constants/supplierStatus';
import {toast} from 'react-hot-toast';
import PropTypes from 'prop-types';

export default function EditSuppliersURl({supplierData}) {
  const [oneProduct, setOneProduct] = useRecoilState(oneProductAtom);
  const {id, product_id, url, supplier_name, status, url_verified} = supplierData;
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [currentValue, setCurrentValue] = useState(url);
  const [selectValue, setSelectValue] = useState(supplierStatus[status]);
  const [urlVerified, setUrlVerified] = useState(url_verified);

  const handleURLChange = (event) => {
    setCurrentValue(event.target.value);
  }

  const handleStatusChange = (event) => {
    setSelectValue(event.target.value);
  }

  const handleVerifiedChange = (event) => {
    setUrlVerified(event.target.value);
  }


  const handleSave = async () => {
    try {
      const {data} = await editCompetitorURL({
        id,
        product_id,
        url: currentValue,
        active: 'true',
        status: selectValue,
        url_verified: urlVerified
      });
      const {product_suppliers} = oneProduct;
      const newSuppliers = product_suppliers.map(item => item.id === data.data.id ? data.data : item);
      setOneProduct(prevState => ({...prevState, product_suppliers: newSuppliers}));
      handleClose();
    } catch (e) {
      handleClose();
      toast.error(e.response.data.message);
    }
  }

  return (
    <>
      <IconButton
        aria-label="edit"
        color="success"
        onClick={handleOpen}
        size="small"
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog open={isOpen} onClose={handleClose}>
        <CustomDialogTitle>Edit {supplier_name}</CustomDialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit the current supplier, please enter a new url or change status.
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Supplier's product URL"
                type="text"
                fullWidth
                variant="standard"
                value={currentValue}
                onChange={handleURLChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="simple-select-required-label-supplier">Status</InputLabel>
                <Select
                  labelId="simple-select-required-label-supplier"
                  id="simple-select-required-supplier"
                  label="Status"
                  value={selectValue}
                  onChange={handleStatusChange}
                >
                  {Object.keys(supplierStatus).map(item => (
                    <MenuItem value={supplierStatus[item]} key={item}>{item}</MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="simple-select-required-label-urlverified">Verified</InputLabel>
                <Select
                  labelId="simple-select-required-label-urlverified"
                  id="simple-select-required-urlverified"
                  label="Verified"
                  value={urlVerified}
                  onChange={handleVerifiedChange}
                >
                  <MenuItem value='false' key='check needed'>Check Needed</MenuItem>
                  <MenuItem value='true' key='verified'>Verified</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

EditSuppliersURl.propTypes = {
  supplierData: PropTypes.object
}
