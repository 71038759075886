import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, Select, TextField} from '@mui/material';
import {useOpenCloseDialog} from '../../hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import CustomDialogTitle from '../CustomDialogTitle';
import {newUserFields} from '../../constants/newUserFields';
import {userValidationSchema} from '../../constants/userValidationSchema';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import {StyledButton} from '../../styleditems';

export default function AddUser({onAddUser}) {
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [selectValue, setSelectValue] = useState('user');
  const defaultFieldValues = {email: '', first_name: '', last_name: '', password: '', confirm_password: ''};
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors, isSubmitSuccessful}
  } = useForm({
    resolver: yupResolver(userValidationSchema),
    defaultValues: defaultFieldValues
  });

  const handleChange = (event) => {
    setSelectValue(event.target.value);
  }

  const onSubmit = newUser => {
    onAddUser({...newUser, role: selectValue});
    handleClose();
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultFieldValues);
    }
  }, [isSubmitSuccessful]);

  const onClose = () => {
    reset(defaultFieldValues);
    handleClose();
  }

  const userFields = newUserFields.map(({field, label, type}) => (
    <Grid item xs={12} sm={12} key={field}>
      <TextField
        required
        id={field}
        name={field}
        label={label}
        type={type}
        defaultValue=""
        fullWidth
        margin="dense"
        {...register(field)}
        error={!!errors[field]}
      />
      <Typography variant="inherit" color="textSecondary">
        {errors[field]?.message}
      </Typography>
    </Grid>
  ))

  return (
    <>
      <StyledButton
        size="small"
        onClick={handleOpen}
      >
        Add User
      </StyledButton>
      <Dialog open={isOpen}>
        <CustomDialogTitle>Add New User</CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {userFields}
            <Grid item xs={12} sm={12} key="role">
              <FormControl fullWidth>
                <InputLabel id="simple-select-required-label">Role</InputLabel>
                <Select
                  labelId="simple-select-required-label"
                  id="simple-select-required"
                  label="Role"
                  value={selectValue}
                  onChange={handleChange}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="user">User</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>Add User</Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddUser.propTypes = {
  onAddUser: PropTypes.func
}
