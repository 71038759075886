import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)({
  mt: 1,
  ml: 1,
  mb: 1,
/*  maxHeight: "30px",*/
  border: "2px solid #53A551",
  backgroundColor: "#53A551",
  color: "#fff",
  '&:hover': {color: "#53A551"}
})
