import React from 'react';
import {StyledBodyCell} from '../../../styleditems';
import Tooltip from '@mui/material/Tooltip';

export default function ProductTitleCell({column, row}) {
  return (
    <Tooltip title={row[column.field]} followCursor={true} key={column.field}>
      <StyledBodyCell>
        {row[column.field]}
      </StyledBodyCell>
    </Tooltip>
  );
}
