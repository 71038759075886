import React, {useState} from 'react';
import {Checkbox, FormControl, FormControlLabel} from '@mui/material';
import {useRecoilState} from 'recoil';
import {showShippingAtom} from '../../state';
import {styled} from '@mui/styles';

const StyledFormLabel = styled(FormControlLabel)({
  color: "#53A551",
  "& .MuiFormControlLabel-label": {fontSize: "0.875rem", lineHeight: "1.75"}
})

export default function ShowShipping() {
  const [checked, setChecked] = useState(false);
  const [showShipping, setShowShipping] = useRecoilState(showShippingAtom);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setShowShipping(!showShipping);
  };

  return (
    <FormControl component="fieldset" color="#53A551">
      <StyledFormLabel
        value="end"
        control={<Checkbox
          sx={{color: "#53A551", p: "2px", ml: 2}}
          checked={checked}
          onChange={handleChange}
          inputProps={{'aria-label': 'controlled'}}
        />}
        label="SHOW PRODUCT/SHIPPING BREAKDOWN"
        labelPlacement="end"
      />
    </FormControl>
  );
}
