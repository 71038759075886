import React from 'react';
import {StyledBodyCell} from '../../../styleditems';
import Link from '@mui/material/Link';

export default function PsPriceCell({column, row}) {
  return (
    <>
      <StyledBodyCell key={column.field}>
        <Link href={row['ps_url']} target="_blank">{row[column.field]}</Link>
      </StyledBodyCell>
      <StyledBodyCell
        key={`${column.fieldName} shipping`}
      >
        {row['ps_shipping'] === "$0.00" ? "-" : row['ps_shipping']}
      </StyledBodyCell>
    </>
  );
}
