import React from 'react';
import {StyledBodyCell, StyledTableRow} from '../../../styleditems';
import Tooltip from '@mui/material/Tooltip';

export default function TagsCell({item, data}) {
  const tagsCell = data[item].map(tag => tag['name']);
  return (
    <StyledTableRow key='tags' sx={{height: "31px"}}>
      <StyledBodyCell key='tags-header'>
        TAXONOMY:
      </StyledBodyCell>
      <Tooltip title={tagsCell.join(", ")}>
        <StyledBodyCell
          key='tags-cell'
          sx={{
            maxWidth: "350px",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {tagsCell.join(", ")}
        </StyledBodyCell>
      </Tooltip>
    </StyledTableRow>
  )
}
