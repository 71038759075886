import React from 'react';
import {useFetchData} from '../../hooks';
import {getAllUsers} from '../../services';
import {usersAtom} from '../../state';
import Spinner from '../../components/Spinner';
import NotFound from '../NotFound';
import UsersList from '../../components/UsersList/UsersList';

export default function Users() {
  const {isLoading, isError, fetchedData} = useFetchData(getAllUsers, usersAtom);
  if (isLoading) return <Spinner />;
  if (isError) return <NotFound />;

  return <UsersList data={fetchedData} />
}
