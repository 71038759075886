import React, {useState} from 'react';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import {Dialog, DialogActions, DialogContent, DialogContentText, TextField} from '@mui/material';
import {useOpenCloseDialog} from '../../hooks';
import {useRecoilState, useRecoilValue} from 'recoil';
import {columnsViewAtom, filterStateAtom, layoutsListAtom, productsHeadersAtom} from '../../state';
import {putLayout, uploadLayouts} from '../../services/layout.service';
import CustomDialogTitle from '../CustomDialogTitle';
import {toast, Toaster} from 'react-hot-toast';

export default function SaveLayout() {
  const {isOpen, setOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [error, setError] = useState('');
  const currentFilterState = useRecoilValue(filterStateAtom);
  const productsHeader = useRecoilValue(productsHeadersAtom);
  const currentColumnsView = useRecoilValue(columnsViewAtom);
  const [layoutState, setLayoutState] = useRecoilState(layoutsListAtom);
  const [currentValue, setCurrentValue] = useState("");
//  const {items} = Object.values(currentColumnsView).find(({name}) => name === 'Displayed');

  const handleChange = (event) => {
    setError('');
    setCurrentValue(event.target.value);
  }

  const handleSave = async () => {
    try {
      if (!currentValue) {
        setError("Empty name");
        return null;
      }
      if (currentValue.match(/[^A-Za-z0-9]+/g)) {
        setError("Name must contain only letters and numbers");
        return null;
      }
      const uploadData = {productsHeader, currentFilterState, currentColumnsView};
      const doubledElement = layoutState.find(({data}) => data.id === currentValue);

      if (doubledElement) {
        if (window.confirm("A Layout with that name already exist. Would you like to replace it?")) {
          const response = await putLayout(doubledElement.id, currentValue, uploadData);
          const {data} = response.data;
          setLayoutState(layouts => layouts.map(item => item === doubledElement ? data : item));
          setOpen(false);
        }
      } else {
        const response = await uploadLayouts(currentValue, uploadData);
        const {data} = response.data;
        setLayoutState([...layoutState, data]);
        setCurrentValue("");
        toast.success("Layout has been saved!");
        setOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Button onClick={handleOpen}>
        <SaveIcon />
        Save Layout
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <CustomDialogTitle>Save Layout</CustomDialogTitle>
        <DialogContent>
          <DialogContentText>
            To save the current layout, please enter a name of layout here.
          </DialogContentText>
          <TextField
            error={!!error}
            autoFocus
            margin="dense"
            id="name"
            label="Name of layout"
            type="text"
            inputProps={{maxLength: 20}}
            fullWidth
            variant="standard"
            value={currentValue}
            onChange={handleChange}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Toaster />
    </>
  )
}
