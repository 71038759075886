import {useState} from 'react';

const useOpenCloseDialog = () => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return {isOpen, setOpen, handleOpen, handleClose}
}

export {useOpenCloseDialog}