import React from 'react';
import {StyledBodyCell, StyledTableRow} from '../../../styleditems';

export default function NameCell({item, data}) {
  return (
    <StyledTableRow key='name' sx={{height: "31px"}}>
      <StyledBodyCell key='name'>
        PRODUCT TITLE:
      </StyledBodyCell>
      <StyledBodyCell key='name-cell'>
        {data[item]}
      </StyledBodyCell>
    </StyledTableRow>
  )
}
