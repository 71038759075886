import React from 'react';
import {StyledBodyCell} from '../../../styleditems';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import {priceStatus} from '../../../constants/priceStatus';

export default function ProductSuppliersCell({column, row, competitors, showPricing}) {
  const {
    url,
    current_price,
    shipping_cost,
    shipping_type,
    total_price,
    status,
    slug,
    supplier_name
  } = row['product_suppliers'].find(({slug}) => slug === column.slug) || {};
  let tooltipText;
  if (slug?.includes('amazon') || slug?.includes('walmart')) {
    tooltipText = <div>
      <div>status: {status}</div>
      <div>{supplier_name}</div>
      <div>{current_price}+{shipping_cost} shipping</div>
    </div>;
    return (
      <>
        <StyledBodyCell key={column.fieldName}>
          <Tooltip title={tooltipText}>
            <Link href={url} sx={{
              '&.MuiLink-root': {color: priceStatus[status]}
            }}
                  target="_blank">{shipping_cost !== "$0.00" ? total_price + "*" : total_price}</Link>
          </Tooltip>
        </StyledBodyCell>
        {showPricing && <StyledBodyCell
          key={`${column.fieldName} name`}
          sx={{'&.MuiTableCell-root': {color: priceStatus[status]}}}
        >
          {supplier_name}
        </StyledBodyCell>}
        {showPricing && <StyledBodyCell
          key={`${column.fieldName} price`}
          sx={{'&.MuiTableCell-root': {color: priceStatus[status]}}}
        >
          {current_price}
        </StyledBodyCell>}
        {showPricing && <StyledBodyCell
          key={`${column.fieldName} shipping`}
          sx={{'&.MuiTableCell-root': {color: priceStatus[status]}}}
        >
          {shipping_type === 'free' ? 'free' : (shipping_cost === "$0.00" ? "-" : shipping_cost)}
        </StyledBodyCell>}
      </>
    )
  } else {
    const {shipping_policy} = competitors.find(item => item.title === supplier_name) || {}
    tooltipText = <div>
      <div>status: {status}</div>
      <div>{current_price}+{shipping_cost} shipping</div>
    </div>;
    return (
      <>
        <StyledBodyCell key={column.fieldName}>
          <Tooltip title={tooltipText}>
            <Link href={url} sx={{
              '&.MuiLink-root': {color: priceStatus[status]}
            }}
                  target="_blank">{shipping_cost !== "$0.00" ? total_price + "*" : total_price}</Link>
          </Tooltip>
        </StyledBodyCell>
        {showPricing && <StyledBodyCell
          key={`${column.fieldName} price`}
          sx={{'&.MuiTableCell-root': {color: priceStatus[status]}}}
        >
          {current_price}
        </StyledBodyCell>}
        {showPricing && <StyledBodyCell
          key={`${column.fieldName} shipping`}
          sx={{'&.MuiTableCell-root': {color: priceStatus[status]}}}
        >
          {shipping_cost}
        </StyledBodyCell>}
        {showPricing && <StyledBodyCell
          key={`${column.fieldName} policy`}
          sx={{'&.MuiTableCell-root': {color: priceStatus[status]}}}
        >
          {shipping_policy}
        </StyledBodyCell>}
      </>
    )
  }
}
