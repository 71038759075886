import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import {Badge, Dialog, DialogActions, Grid} from '@mui/material';
import LogsMultiFilterList from './LogsMultiFilterList';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {
  logsAtom, logsDefaultAtom, logsFiltersCountState, logsFilterStateAtom, logsrowsPerPageAtom
} from '../../state';
import uniqueId from 'lodash/uniqueId';
import {useOpenCloseDialog} from '../../hooks';
import CustomDialogTitle from '../CustomDialogTitle';
import {getLogs} from '../../services';
import {toast} from 'react-hot-toast';
import {setPlainData} from '../../helpers';
import MiniLoader from '../MiniLoader';

export default function LogsMultiFilter() {
  const [isLoading, setLoading] = useState(false);
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [globalFilter, setGlobalFilter] = useRecoilState(logsFilterStateAtom);
  const defaultFilterState = {id: +uniqueId(), column: "", operator: "", value: ""};
  const [filterState, setFilterState] = useState(globalFilter);
  const filtersCount = useRecoilValue(logsFiltersCountState);
  const setFilteredData = useSetRecoilState(logsAtom);
  const defaultProductsList = useRecoilValue(logsDefaultAtom);
  const [rowsPerPage, setRowsPerPage] = useRecoilState(logsrowsPerPageAtom);

  useEffect(() => {
    setFilterState(globalFilter);
  }, [globalFilter])

  const handleAddFilter = () => {
    if (filterState.length > 6) return null;
    setFilterState(filter => [...filter, defaultFilterState]);
  }

  const handleApplyFilter = async () => {
    try {
      setLoading(true);
      const filterData = filterState.map(({column, operator, value}) => {
          if (column !== '' && operator !== '' && value !== '') return ({
            column,
            operator,
            value
          })
        }
      );
      if (filterData.length === 0) {
        toast.error('Empty filter field');
        setLoading(false)
        return null;
      }
      const response = await getLogs({perPage: rowsPerPage, filteredData: filterData});
      const {data} = response.data;
      setFilteredData(_ => {
          const {rows} = setPlainData(data);
          return {rows: rows, current_page: data['current_page'], total_pages: data['total_pages']}
        }
      );
      setGlobalFilter(filterState);
      handleClose();
      setLoading(false);
    } catch (e) {
      console.log(e);
//      toast.error(e.response.statusText);
      handleClose();
      setLoading(false)
    }
  }

  const handleResetFilter = () => {
    setFilteredData(defaultProductsList);
    setGlobalFilter([defaultFilterState])
    setFilterState([defaultFilterState]);
    setRowsPerPage(25);
    handleClose();
  }

  return (
    <>
      <Button onClick={handleOpen}>
        <Badge badgeContent={filtersCount} color="primary">
          <FilterListIcon />
          Filter
        </Badge>
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <CustomDialogTitle>Filter</CustomDialogTitle>
        <Grid container>
          <LogsMultiFilterList
            filterArr={filterState}
            setFilter={setFilterState}
          />
        </Grid>
        <DialogActions>
          <Button onClick={handleAddFilter}>Add filter</Button>
          <Button onClick={handleApplyFilter}>
            {isLoading ? 'Loading...' : 'Apply filter'}
            {isLoading && <MiniLoader />}
          </Button>
          <Button onClick={handleResetFilter}>Reset All Filters</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
