import {atom, selector} from 'recoil';
import uniqueId from 'lodash/uniqueId';

const logsDefaultAtom = atom({
  key: "LogsDefault",
  default: {}
});

const logsAtom = atom({
  key: "Logs",
  default: logsDefaultAtom
});

const logsrowsPerPageAtom = atom({
  key: "logsrowsPerPage",
  default: 25
});

const logsFilterStateAtom = atom({
  key: "logsFilterState",
  default: [{id: +uniqueId(), column: "", operator: "", value: ""}]
})

const logsFiltersCountState = selector({
  key: "logsFiltersCountSelector",
  get: ({get}) => {
    return get(logsFilterStateAtom).filter(item => item.value&&item.operator).length;
  }
})

export {logsDefaultAtom, logsAtom, logsrowsPerPageAtom, logsFilterStateAtom, logsFiltersCountState}
