import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

export default function DeleteButton({onClick}) {
  return (
    <IconButton color="error" onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  );
}

DeleteButton.propTypes = {
  onClick: PropTypes.func
}