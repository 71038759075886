export const productColumnHeaders = [
  {"field": "id", "fieldName": "ID", "visible": false, "type": "number"},
  {"field": "part_number", "fieldName": "Part #", "visible": false, "type": "string"},
  {"field": "vendor_number", "fieldName": "Vendor #", "visible": false, "type": "string"},
  {"field": "manufacturer", "fieldName": "Manufacturer", "visible": true, "type": "string"},
  {"field": "brand.title", "fieldName": "Brand", "visible": true, "type": "string"},
  {"field": "model_number", "fieldName": "Model #", "visible": true, "type": "string"},
  {"field": "product_detail.stock_locs", "fieldName": "Stock Locs", "visible": false, "type": "number"},
  {"field": "upc_code", "fieldName": "UPC", "visible": true, "type": "string"},
  {"field": "manufacturer_case_pack", "fieldName": "Manufacturer Case Pack UPC", "visible": false, "type": "string"},
  {"field": "product_detail.uom", "fieldName": "Unit of Measure", "visible": false, "type": "string"},
  {"field": "product_detail.uom_qty", "fieldName": "UOM QTY", "visible": false, "type": "number"},
  {"field": "asin_number", "fieldName": "ASIN", "visible": false, "type": "string"},
  {"field": "walmart_id", "fieldName": "Walmart Product ID", "visible": false, "type": "string"},
  {"field": "pdp_family_id", "fieldName": "PDP Family ID", "visible": false, "type": "string"},
  {"field": "product_detail.attr_name_1", "fieldName": "Attribute Name 1", "visible": true, "type": "string"},
  {"field": "product_detail.attr_value_1", "fieldName": "Attribute Value 1", "visible": true, "type": "string"},
  {"field": "product_detail.sequence_1", "fieldName": "Sequence 1", "visible": false, "type": "string"},
  {"field": "product_detail.attr_name_2", "fieldName": "Attribute Name 2", "visible": true, "type": "string"},
  {"field": "product_detail.attr_value_2", "fieldName": "Attribute Value 2", "visible": true, "type": "string"},
  {"field": "product_detail.sequence_2", "fieldName": "Sequence 2", "visible": false, "type": "string"},
  {"field": "tags", "fieldName": "Taxonomy", "visible": true, "type": "string"},
  {"field": "ps_price", "fieldName": "PaintSupply.com Price", "visible": true, "type": "number"},
  {"field": "min_price_diff_doll", "fieldName": "PS – Cheapest, $", "visible": true, "type": "number"},
  {"field": "min_price_diff_perc", "fieldName": "PS vs Cheapest, %", "visible": true, "type": "number"},
  {"field": "product_suppliers", "fieldName": "Amazon Buy Box", "visible": true, "slug": "amazon", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Amazon Buy Box", "visible": false, "slug": "amazon", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Amazon Shipping Cost", "visible": true, "slug": "amazon", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Amazon 2", "visible": true, "slug": "amazon-1", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Amazon 2", "visible": false, "slug": "amazon-1", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Amazon 1 Shipping Cost", "visible": true, "slug": "amazon-1", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Amazon 3", "visible": true, "slug": "amazon-2", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Amazon 3", "visible": false, "slug": "amazon-2", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Amazon 2 Shipping Cost", "visible": true, "slug": "amazon-2", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Amazon 4", "visible": true, "slug": "amazon-3", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Amazon 4", "visible": false, "slug": "amazon-3", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Amazon 3 Shipping Cost", "visible": true, "slug": "amazon-3", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Amazon 5", "visible": true, "slug": "amazon-4", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Amazon 5", "visible": false, "slug": "amazon-4", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Amazon 4 Shipping Cost", "visible": true, "slug": "amazon-4", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Walmart Buy Box", "visible": true, "slug": "walmart", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Walmart Buy Box", "visible": false, "slug": "walmart", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Walmart Shipping Cost", "visible": true, "slug": "walmart", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Walmart 2", "visible": true, "slug": "walmart-1", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Walmart 2", "visible": false, "slug": "walmart-1", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Walmart 1 Shipping Cost", "visible": true, "slug": "walmart-1", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Walmart 3", "visible": true, "slug": "walmart-2", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Walmart 3", "visible": false, "slug": "walmart-2", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Walmart 2 Shipping Cost", "visible": true, "slug": "walmart-2", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Walmart 4", "visible": true, "slug": "walmart-3", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Walmart 4", "visible": false, "slug": "walmart-3", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Walmart 3 Shipping Cost", "visible": true, "slug": "walmart-3", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Walmart 5", "visible": true, "slug": "walmart-4", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Walmart 5", "visible": false, "slug": "walmart-4", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Walmart 4 Shipping Cost", "visible": true, "slug": "walmart-4", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Home Depot", "visible": true, "slug": "home-depot", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Home Depot", "visible": false, "slug": "home-depot", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Home Depot Shipping Cost", "visible": true, "slug": "home-depot", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Lowes", "visible": true, "slug": "lowes", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Lowes", "visible": false, "slug": "lowes", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Lowes Shipping Cost", "visible": true, "slug": "lowes", "type": "number"},*/
  {"field": "product_suppliers", "fieldName": "Menards", "visible": true, "slug": "menards", "type": "number"},
  {"field": "updated_at", "fieldName": "Date Last Changed – Menards", "visible": false, "slug": "menards", "type": "number"},
  /*  {"field": "product_suppliers", "fieldName": "Menards Shipping Cost", "visible": true, "slug": "menards", "type": "number"},*/
  {
    "field": "product_suppliers",
    "fieldName": "Max Warehouse",
    "visible": true,
    "slug": "max-warehouse",
    "type": "number"
  },
  {
    "field": "updated_at",
    "fieldName": "Date Last Changed – Max Warehouse",
    "visible": false,
    "slug": "max-warehouse",
    "type": "number"
  },
  /*  {"field": "product_suppliers", "fieldName": "Max Warehouse Shipping Cost", "visible": true, "slug": "max-warehouse", "type": "number"},*/
  {
    "field": "product_suppliers",
    "fieldName": "Painters Solutions",
    "visible": true,
    "slug": "painters-solutions",
    "type": "number"
  },
  {
    "field": "updated_at",
    "fieldName": "Date Last Changed – Painters Solutions",
    "visible": false,
    "slug": "painters-solutions",
    "type": "number"
  },
  /*  {"field": "product_suppliers", "fieldName": "Painters Solutions Shipping Cost", "visible": true, "slug": "painters-solutions", "type": "number"},*/
  {
    "field": "product_suppliers",
    "fieldName": "Integrity Supply",
    "visible": true,
    "slug": "integrity-supply",
    "type": "number"
  },
  {
    "field": "updated_at",
    "fieldName": "Date Last Changed – Integrity Supply",
    "visible": false,
    "slug": "integrity-supply",
    "type": "number"
  },
  /*  {"field": "product_suppliers", "fieldName": "Integrity Supply Shipping Cost", "visible": true, "slug": "integrity-supply", "type": "number"},*/
  {"field": "product_detail.discount_qty_1", "fieldName": "Qty Discount Qty 1", "visible": false, "type": "number"},
  {"field": "product_detail.discount_pct_1", "fieldName": "Qty Discount Pct 1", "visible": false, "type": "number"},
  {"field": "product_detail.discount_price_1", "fieldName": "Qty Discount Price 1", "visible": false, "type": "number"},
  {"field": "product_detail.discount_qty_2", "fieldName": "Qty Discount Qty 2", "visible": false, "type": "number"},
  {"field": "product_detail.discount_pct_2", "fieldName": "Qty Discount Pct 2", "visible": false, "type": "number"},
  {"field": "product_detail.discount_price_2", "fieldName": "Qty Discount Price 2", "visible": false, "type": "number"},
  {"field": "product_detail.div_11_units_sold", "fieldName": "Div 11 Units Sold", "visible": false, "type": "number"},
  {"field": "product_detail.div_12_units_sold", "fieldName": "Div 12 Units Sold", "visible": false, "type": "number"},
  {"field": "product_detail.div_12_orders", "fieldName": "Div 12 Orders", "visible": false, "type": "number"},
  {"field": "ps_url", "fieldName": "PaintSupply.com Product Page", "visible": false, "type": "string"},
  {"field": "pss_url", "fieldName": "PSS Product Page", "visible": false, "type": "string"},
  /*
    {"field": "asin_number", "fieldName": "ASIN Number", "visible": false, "type": "string"},

    {"field": "status", "fieldName": "Status", "visible": false, "type": "string"}*/
]
