import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import {menuRoutes} from '../../constants/menuRoutes';
import Logo from './Logo';
import CustomMenuItem from './CustomMenuItem';
import PropTypes from 'prop-types';

export default function MobileView({handleOpenNavMenu, anchorElNav, handleCloseNavMenu, currentUser}) {
  const menu = menuRoutes.map(({route, text}) => {
      if ((currentUser.role !== "Admin" && route === "/users") || (currentUser.role !== "Admin" && route === "/competitors")) {
        return null;
      }
      return <CustomMenuItem key={text} text={text} route={route} alignment="left" />
    }
  )

  return (
    <>
      <Typography
        component="div"
        sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
      >
        <Logo />
      </Typography>
      <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="primary"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: {xs: 'block', md: 'none'}
          }}
        >
          {menu}
        </Menu>
      </Box>
    </>
  );
}

MobileView.propTypes = {
  handleOpenNavMenu: PropTypes.func,
  anchorElNav: PropTypes.string,
  handleCloseNavMenu: PropTypes.func
}
