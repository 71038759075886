import React from 'react';
import moment from 'moment/moment';
import {SuppliersStyledBodyCell} from '../../../styleditems';

export default function LastCheckedCell({field, row}) {
  return (
    <SuppliersStyledBodyCell key={field}>
      {row[field] === null || typeof row[field] === 'object' ? '' : moment(row[field]).format('MM/DD/YYYY HH:mm')}
    </SuppliersStyledBodyCell>
  )
}
