import {API_URL} from '../constants/railsAPIURL';
import axios from 'axios';

export function login({email, password, rememberMe}) {
  let data = new FormData();
  data.append("user[email]", email);
  data.append("user[password]", password);
  data.append("user[remember_me]", rememberMe);
  return axios.post(`${API_URL}login`, data)
}

export function forgotPassword(email) {
  let data = new FormData();
  data.append("user[email]", email);
  return axios.post(`${API_URL}password`, data)
}

export function resetPassword({password, confirm_password, token}) {
  let data = new FormData();
  data.append("user[password]", password);
  data.append("user[confirm_password]", confirm_password);
  data.append("user[reset_password_token]", token);
  return axios.put(`${API_URL}password`, data)
}


export function logout({email, password}) {
  let data = new FormData();
  data.append("user[email]", email);
  data.append("user[password]", password);
  return axios.delete(`${API_URL}logout`, {data})
}
