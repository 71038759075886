import React from 'react';
import Button from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {
  productsHeadersDefaultAtom,
  columnsViewDefaultAtom,
  productsListAtom,
  productsListDefaultAtom
} from '../../state';
import uniqueId from 'lodash/uniqueId';
import {useSetLayouts} from '../../hooks';

export default function ResetLayout() {
  const {setColumnHeaders, setColumnsView, setFilterState, setSelectedLayout} = useSetLayouts();
  const productsHeaderDefault = useRecoilValue(productsHeadersDefaultAtom);
  const columnsViewDefault = useRecoilValue(columnsViewDefaultAtom);
  const defaultProductsList = useRecoilValue(productsListDefaultAtom);
  const setDefaultData = useSetRecoilState(productsListAtom);

  const handleReset = () => {
    setColumnHeaders(productsHeaderDefault);
    setColumnsView(()=>columnsViewDefault);
    setFilterState([{id: uniqueId("id-"), column: "", operator: "", value: "", dataType: "number"}]);
    setSelectedLayout(0);
    setDefaultData(defaultProductsList);
  }

  return (
    <Button onClick={handleReset}>
      <RestartAltIcon />
      Reset Layout
    </Button>
  )
}
