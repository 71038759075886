import React from 'react';
import {Grid} from '@mui/material';
import MultiFilterItem from './MultiFilterItem';
import PropTypes from 'prop-types';

export default function MultiFilterList({filterArr, setFilter}) {
  return (
    <Grid container spacing={1} sx={{pl: 2, pr: 2, width: "700px"}}>
      {filterArr.map(item =>
        <MultiFilterItem
          key={item.id}
          id={item.id}
          row={item}
          length={filterArr.length}
          setFilter={setFilter}
          filterArr={filterArr}
        />
      )}
    </Grid>
  )
}

MultiFilterList.propTypes = {
  filterArr: PropTypes.array,
  setFilter: PropTypes.func
}

