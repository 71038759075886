import {useEffect, useState} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {setPlainData} from '../helpers';
import {authAtom} from '../state';

const useFetchData = (callback, atom, params = '') => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [fetchedData, setFetchedData] = useRecoilState(atom);
  const [errorMessage, setErrorMessage] = useState('');
  const removeAuth = useSetRecoilState(authAtom);

  useEffect(async () => {
    try {
        const response = await callback(params);
        const {data} = response.data;
        if (atom.key === 'productsListDefault') {
          setFetchedData(setPlainData(data));
        } else {
          setFetchedData(data);
        }
      setLoading(false);
    } catch (e) {
      if (e?.response?.status === 401) {
        removeAuth(null);
        localStorage.removeItem('user');
      }
      setErrorMessage(e?.response?.data?.error);
      setError(true);
      setLoading(false);
    }
  }, [params]);
  return {isLoading, isError, fetchedData, errorMessage};
}

export {useFetchData};
