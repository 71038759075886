import {styled} from '@mui/styles';
import TableRow from '@mui/material/TableRow';

export const StyledProductTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ece9e9',
    '& td:nth-child(1), & td:nth-child(2), & td:nth-child(3)': {
      backgroundColor: 'inherit'
    },
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#fff',
    '& td:nth-child(1), & td:nth-child(2), & td:nth-child(3)': {
      backgroundColor: 'inherit'
    },
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '& td:nth-child(1), & td:nth-child(2)': {
    position: "sticky",
    zIndex: 200,
    textOverflow: "ellipsis",
  },
  '& td:nth-child(1)': {
    left: "0",
    minWidth: "100px"
  },
  '& td:nth-child(2)': {
    left: "132px",
    minWidth: "150px",
    overflow: "hidden",
    borderRight: "3px solid #A9A3A34D"
  },
  '&.Mui-selected:hover, &.Mui-selected': {
    backgroundColor: "#d5e7d5",
  }
}));
