import React from 'react';
import {StyledBodyCell, StyledTableRow} from '../../../styleditems';
import Link from '@mui/material/Link';
import EditStatusCell from '../EditStatusCell';

export default function PSUrlCell({item, data}) {
  if (item === 'pss_url' || item === 'ps_url') {
    return (
      <StyledTableRow key={item} sx={{height: "31px"}}>
        <StyledBodyCell key={item}>
          {item.toUpperCase().replaceAll('_', ' ')}:
        </StyledBodyCell>
        <StyledBodyCell key={data[item]}>
          <Link href={data[item]} target="_blank">{data[item]}</Link>
        </StyledBodyCell>
      </StyledTableRow>
    )
  } else {
    return (
      <StyledTableRow key={item} sx={{height: "31px"}}>
        <StyledBodyCell key={item}>
          {item.toUpperCase().replaceAll('_', ' ')}:
        </StyledBodyCell>
        <StyledBodyCell key={data[item]}>
          {item !== "status" ? data[item] : <EditStatusCell id={data.id} productStatus={data.status} />}
        </StyledBodyCell>
      </StyledTableRow>
    )
  }
}
