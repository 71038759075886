import React from 'react';
import {NavLink} from 'react-router-dom';
import {StyledButton} from '../../styleditems';
import PropTypes from 'prop-types';

export default function DetailViewButton({id, status}) {
  return status === "Active" ? (
    <StyledButton
      component={NavLink}
      to={{pathname: `/products/${id}`}}
      target="_blank"
      sx={{m: "3px"}}
    >
      Details
    </StyledButton>
  ) : (
    <StyledButton
      component={NavLink}
      to={{pathname: `/products/${id}`}}
      sx={{
        m: "3px",
        border: "2px solid #878887",
        backgroundColor: "#878887",
        color: "#fff",
        '&:hover': {color: "#252424"}
      }}
    >
      Details
    </StyledButton>
  );
}

DetailViewButton.propTypes = {
  id: PropTypes.number,
  status: PropTypes.string
}


