import React from 'react';
import Link from '@mui/material/Link';
import {SuppliersStyledBodyCell} from '../../../styleditems';

export default function SupplierNameCell({field, row, amazon, walmart}) {
  let url, supplierField;
  if (/amazon/.test(row.slug)) {
    url = amazon['url'];
    supplierField = row[field] ? row[field] : `Amazon:`
  } else if (/walmart/.test(row.slug)) {
    url = walmart['url']
    supplierField = row[field] ? row[field] : `Walmart:`
  } else {
    supplierField = row[field]
    url = row['url']
  }
  return (
    <SuppliersStyledBodyCell key={field}>
      {url == null || url == '' || url == 'null' || typeof row[field] === 'object' ? supplierField :
        <Link href={url} target="_blank">{supplierField}</Link>}
    </SuppliersStyledBodyCell>
  )
}
