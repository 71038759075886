import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import SignOut from '../SignOut';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import UserProfile from '../UserProfile';

export default function MenuAvatar({handleOpenUserMenu, stringAvatar, currentUser, anchorElUser, handleCloseUserMenu}) {
  return (
    <Box sx={{flexGrow: 0}}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
          <Avatar
            sx={{backgroundColor: "#53A551"}}
            {...stringAvatar(`${currentUser['first_name']} ${currentUser['last_name']}`)}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{mt: '45px'}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <UserProfile user={currentUser} onCloseUserMenu={handleCloseUserMenu}/>
        <SignOut />
      </Menu>
    </Box>
  );
}

MenuAvatar.propTypes = {
  handleOpenUserMenu: PropTypes.func,
  stringAvatar: PropTypes.func,
  currentUser: PropTypes.object,
  anchorElUser: PropTypes.object,
  handleCloseUserMenu: PropTypes.func
}
