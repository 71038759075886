import React from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import {Badge, Dialog, DialogActions} from '@mui/material';
import LoadLayoutItem from './LoadLayoutItem';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import {useFetchData, useOpenCloseDialog} from '../../hooks';
import {layoutsListAtom, selectedLayoutAtom} from '../../state';
import {getLayouts} from '../../services/layout.service';
import Spinner from '../Spinner';
import NotFound from '../../pages/NotFound';
import CustomDialogTitle from '../CustomDialogTitle';
import Tooltip from '@mui/material/Tooltip';
import {useRecoilState} from 'recoil';

export default function LoadLayout() {
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const {loading, isError, fetchedData} = useFetchData(getLayouts, layoutsListAtom);
  const [selectedLayout, setSelectedLayout] = useRecoilState(selectedLayoutAtom);

  if (loading) return <Spinner />
  if (isError) return <NotFound />

  const layouts = fetchedData.length ?
    (<List sx={{p: "0 5px 5px 5px", overflowY: "auto", minWidth: "300px"}}>
      {[...fetchedData].sort((a,b)=>a.id-b.id).map(({id, data}) =>
        <LoadLayoutItem
          key={id}
          id={id}
          row={data}
          onClose={handleClose}
          setBadge={setSelectedLayout}
        />)}
    </List>) :
    <Paper elevation={0} sx={{mr: 1, ml: 1}}>No saved layouts yet</Paper>

  return (
    <>
      <Button onClick={handleOpen}>
        <Tooltip title={selectedLayout === 0 ? '' : selectedLayout} arrow>
          <Badge badgeContent={selectedLayout !== 0 ? '' : 0} color="primary">
            <DownloadIcon />
            Load Layout
          </Badge>
        </Tooltip>
      </Button>
      <Dialog onClose={handleClose} open={isOpen}>
        <CustomDialogTitle>Select layout</CustomDialogTitle>
        {layouts}
        <DialogActions>
          <Button onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
