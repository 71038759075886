import React from 'react';
import {useOpenCloseDialog, useSetLayouts} from '../../hooks';
import Button from '@mui/material/Button';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material';
import CustomDialogTitle from '../CustomDialogTitle';
import ColumnsViewList from './ColumnsViewList';
import {columnsViewAtom, productsHeadersDefaultAtom} from '../../state';
import {useRecoilValue} from 'recoil';
import uniqueId from 'lodash/uniqueId';
import {getSortedColumns} from '../../helpers';

export default function ColumnsView() {
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const {setColumnHeaders, setColumnsView} = useSetLayouts();
  const allColumns = useRecoilValue(productsHeadersDefaultAtom);
  const columnsView = useRecoilValue(columnsViewAtom);
  const invisible = Object.values(columnsView)[0]['items'];
  const visible = Object.values(columnsView)[1]['items'];

  const handleSort = () => {
    const sortedInvisible = getSortedColumns(allColumns, invisible);
    setColumnsView(() => ({
      [uniqueId()]: {
        name: "Available",
        items: sortedInvisible
      },
      [uniqueId()]: {
        name: "Displayed",
        items: visible
      }
    }));
  }

  const handleApply = () => {
    setColumnHeaders(prevState => {
      const newVisibleColumns = visible.map(item => (
        {...prevState.find(column => column.fieldName === item.content), visible: true}
      ));
      const newInvisibleColumns = invisible.map(item => (
        {...prevState.find(column => column.fieldName === item.content), visible: false}
      ));

      return [...newVisibleColumns, ...newInvisibleColumns];
    })
    handleClose();
  }

  const handleShowAll = () => {
    setColumnHeaders(() => allColumns.map(item => ({...item, visible: true})));
    setColumnsView(() => ({
      [uniqueId()]: {
        name: "Available",
        items: []
      },
      [uniqueId()]: {
        name: "Displayed",
        items: allColumns.map(({fieldName}) => ({id: uniqueId(), content: fieldName}))
      }
    }));
  }

  const handleHideAll = () => {
    setColumnHeaders(() => allColumns.map(item => ({...item, visible: false})));
    setColumnsView(() => ({
      [uniqueId()]: {
        name: "Available",
        items: allColumns.map(({fieldName}) => ({id: uniqueId(), content: fieldName}))
      },
      [uniqueId()]: {
        name: "Displayed",
        items: []
      }
    }));
  }

  return (
    <>
      <Button onClick={handleOpen}>
        <ViewColumnIcon />
        Columns
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <CustomDialogTitle>Columns Displayed</CustomDialogTitle>
        <DialogContent>
          <DialogContentText>
            To change the columns displayed on your dashboard, and the sequence in which they appear, drag and drop the
            appropriate column name.
          </DialogContentText>
          <ColumnsViewList />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSort}>Sort Available</Button>
          <Button onClick={handleApply}>Apply</Button>
          <Button onClick={handleHideAll}>Hide All</Button>
          <Button onClick={handleShowAll}>Show All</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
