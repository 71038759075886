import React, {useState} from 'react';
import {useOpenCloseDialog} from '../../hooks';
import Button from '@mui/material/Button';
import {Dialog, DialogActions, Stack, TextField} from '@mui/material';
import CustomDialogTitle from '../CustomDialogTitle';
import DateAdapter from "@mui/lab/AdapterMoment"
import {LocalizationProvider, MobileDatePicker} from '@mui/lab';
import moment from 'moment';
import {getPriceHistory} from '../../services';
import PropTypes from 'prop-types';
import {useSetRecoilState} from 'recoil';
import {priceHistoryAtom} from '../../state';
import {toast, Toaster} from 'react-hot-toast';
import {DateSelectButton} from './PeriodicCharts';

export default function CustomDateFilter({id}) {
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const setPriceHistory = useSetRecoilState(priceHistoryAtom);

  const handleMinChange = (min) => {
    setStartValue(min);
  }

  const handleMaxChange = (max) => {
    setEndValue(max);
  }

  const handleApply = async () => {
    try {
      const {data} = await getPriceHistory(
        id,
        startValue.format('MM/DD/YYYY'),
        endValue.format('MM/DD/YYYY')
      );
      if (data.data.length === 0) {
        handleClose();
        toast.error("There is no data in selected range. Select another range");
        return null;
      }
      setPriceHistory(data.data);
      handleClose();
    } catch (e) {
      console.log(e)
    }
  }

  const handleReset = () => {
    setStartValue(null);
    setEndValue(null);
  }

  return (
    <>
      <DateSelectButton onClick={handleOpen}>
        Date Range
      </DateSelectButton>
      <Dialog open={isOpen} onClose={handleClose}>
        <CustomDialogTitle>Date Range</CustomDialogTitle>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Stack direction="row" spacing={3} padding={2} sx={{label: {color: "green"}}}>
            <MobileDatePicker
              disableFuture
              label="Select start date"
              value={startValue}
              maxDate={endValue ? moment(endValue) : moment()}
              onChange={handleMinChange}
              renderInput={(params) => (
                <TextField {...params} />
              )}
            />
            <MobileDatePicker
              disableFuture
              label="Select end date"
              value={endValue}
              onChange={handleMaxChange}
              minDate={startValue ? moment(startValue) : null}
              maxDate={moment()}
              renderInput={(params) => (
                <TextField {...params} />
              )}
            />
          </Stack>
        </LocalizationProvider>
        <DialogActions>
          <Button onClick={handleApply}>Apply Range</Button>
          <Button onClick={handleReset}>Reset Range</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Toaster />
    </>
  )
}

CustomDateFilter.propTypes = {
  id: PropTypes.string
}
