import React from 'react';
import {isArray, isBoolean, isEmpty, isNull, isNumber, isPlainObject, isString} from 'lodash';

const setPlainData = (props) => {
  const {rows} = props;
  const plainData = rows.map(row => flatten(row));
  return {...props, rows: plainData};
}

function flatten(o, prefix = '', result = {}, keepNull = true) {
  if (isString(o) || isNumber(o) || isArray(o) || isBoolean(o) || (keepNull && isNull(o))) {
    result[prefix] = o;
    return result;
  }

  if (isPlainObject(o)) {
    for (let i in o) {
      let pref = prefix;
      if (isEmpty(prefix)) {
        pref = i;
      } else {
        pref = prefix + '.' + i;
      }
      flatten(o[i], pref, result, keepNull);
    }
    return result;
  }
  return result;
}

export {setPlainData}
