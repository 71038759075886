import React from 'react';
import {styled} from '@mui/styles';
import LogsMultiFilter from './LogsMultiFilter';

const ToolbarWrapper = styled("div")({
  padding: "5px",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
})

export default function LogsProductsToolbar() {
  return (
    <ToolbarWrapper>
      <LogsMultiFilter />
    </ToolbarWrapper>
  );
}
