import React from 'react';
import {StyledBodyCell} from '../../../styleditems';
import moment from 'moment';

export default function UpdatedAtCell({column, row}) {
  const {updated_at} = row['product_suppliers'].find(({slug}) => slug === column.slug) || {};
  return (
    <StyledBodyCell
      key={column.fieldName}
    >
      {moment(updated_at).format('MM/DD/YYYY')}
    </StyledBodyCell>
  )
}
