import React from 'react';
import {useRecoilState} from 'recoil';
import {authAtom} from '../../state';
import {logout} from '../../services';
import {MenuItem} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useHistory} from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';

export default function SignOut() {
  const [auth, setAuth] = useRecoilState(authAtom);
  const history = useHistory();

  const handleLogout = async () => {
    try {
      await logout(auth);
      localStorage.removeItem("user");
      setAuth(null);
      history.push('/login');
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <MenuItem onClick={handleLogout}>
      <IconButton
        aria-label="logout"
        color="success"
      >
        <LogoutIcon />
      </IconButton>
      <Typography textAlign="center">
        Logout
      </Typography>
    </MenuItem>
  );
}
