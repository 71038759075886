import {useRecoilValue} from 'recoil';
import {productsHeadersAtom} from '../state';
import {getFullProductsHeader} from '../helpers';
import {fixedProductColumnHeaders} from '../constants/fixedProductColumnHeaders';
import {productFilterColumns} from '../constants/productFilterColumns';

const useGetFilterColumns = () => {
  const productsHeader = useRecoilValue(productsHeadersAtom);
  const fullProductsHeader = getFullProductsHeader(productsHeader);
  return [...fixedProductColumnHeaders, ...fullProductsHeader].filter(item => 'undefined' !== typeof productFilterColumns[item.fieldName]);
}

export {useGetFilterColumns}
