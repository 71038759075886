import React, {useState} from 'react';
import {useOpenCloseDialog} from '../../hooks';
import {toast} from 'react-hot-toast';
import Button from '@mui/material/Button';
import {Dialog, DialogActions, DialogContent, DialogContentText, TextField} from '@mui/material';
import CustomDialogTitle from '../CustomDialogTitle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {addSKU} from '../../services';

export default function AddSku() {
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [currentValue, setCurrentValue] = useState("");

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
  }

  const handleSave = async () => {
    try {
      if (currentValue.length === 0) {
        toast.error("Please, enter SKU");
        return null;
      }
      await addSKU({sku: currentValue});
      setCurrentValue('');
      handleClose();
    } catch ({response}) {
      toast.error(response?.data?.errors?.sku[0]);
    }
  }

  return (
    <>
      <Button onClick={handleOpen}>
        <AddCircleOutlineIcon />
        Add SKU
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <CustomDialogTitle>Add SKU</CustomDialogTitle>
        <DialogContent>
          <DialogContentText>
            To add new SKU, please enter a SKU number.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="SKU"
            label="SKU"
            type="text"
            inputProps={{maxLength: 20}}
            fullWidth
            variant="standard"
            value={currentValue}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
