import React, {useState} from 'react';
import {Grid, MenuItem, TextField} from '@mui/material';
import DeleteButton from '../DeleteButton';
import {stringOperators} from '../../constants/operators';
import PropTypes from 'prop-types';

const supplierStatus = {
  "search_started": 0,
  "fetch_started": 1,
  "processed": 2,
  "request_failed": 3,
  "parsing_failed": 4,
  "failed": 5,
  "not_founded": 6
}
const call_type = {"scrap_price": 0, "imac_sync": 1}

export default function LogsMultiFilterItem({id, row, length, setFilter, filterArr}) {
  const {column, operator, value} = row;
  const [selectedColumn, setSelectedColumn] = useState(column);
  const [selectedOperator, setSelectedOperator] = useState(operator);
  const [selectedStatus, setSelectedStatus] = useState(value);
  const [selectedCallType, setSelectedCallType] = useState(value);
  const [inputValue, setInputValue] = useState(value);

  const filterSelectColumns = ['Status'].map((option) => {
    return (
      <MenuItem
        key={option}
        value={option}
        disabled={filterArr.some(filter => filter.column === option)}
      >
        {option}
      </MenuItem>
    )
  })

  const filterSelectOperators = stringOperators.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    )
  );

  const statusSelect = Object.keys(supplierStatus).map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    )
  );

  const callTypeSelect = Object.keys(call_type).map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    )
  );

  const handleColumnsChange = (event) => {
    const columnValue = event.target.value;
    setSelectedColumn(columnValue);
    if (columnValue === 'Status' || columnValue === 'Call Type') {
      setSelectedOperator('equals');
      setFilter(items => items.map((row) => {
        return row.id === id ? {...row, operator: 'equals'} : row;
      }));
    }
    setFilter(items => items.map((row) => {
      return row.id === id ? {...row, column: columnValue} : row;
    }));
  };

  const handleOperatorsChange = (event) => {
    setSelectedOperator(event.target.value)
    setFilter(items => items.map((row) => {
      return row.id === id ? {...row, operator: event.target.value} : row;
    }));
  }

  const handleInputChange = (event) => {
    const currentValue = event.target.value;
    if (!selectedColumn) return null;

    setInputValue(currentValue);
    setFilter(items => items.map((row) => {
      return row.id === id ? {...row, value: currentValue.trim()} : row;
    }));
  };

  const handleStatusChange = (event) => {
    if (selectedColumn === "Status") {
      setSelectedStatus(event.target.value);
      setFilter(items => items.map((row) => {
        return row.id === id ? {...row, value: supplierStatus[event.target.value]} : row;
      }));
    } else {
      setSelectedCallType(event.target.value);
      setFilter(items => items.map((row) => {
        return row.id === id ? {...row, value: call_type[event.target.value]} : row;
      }));
    }
  }

  const handleDeleteFilter = () => {
    if (length === 1) return null;
    setFilter(items => items.filter(item => item.id !== id));
  }

  return (
    <>
      <Grid item xs={5}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              transitionDuration: 0,
            },
          }}
          label="columns"
          value={selectedColumn}
          onChange={handleColumnsChange}
          variant="standard"
          fullWidth
        >
          {filterSelectColumns}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              transitionDuration: 0,
            },
          }}
          disabled={selectedColumn === 'Status' || selectedColumn === "Call Type"}
          label="operators"
          value={selectedOperator}
          onChange={handleOperatorsChange}
          variant="standard"
          fullWidth
        >
          {filterSelectOperators}
        </TextField>
      </Grid>
      <Grid item xs={3}>
        {selectedColumn !== 'Status' && selectedColumn !== 'Call Type' ?
          <TextField
            multiline
            label="value"
            variant="standard"
            value={inputValue}
            onChange={handleInputChange}
          /> :
          <TextField
            select
            SelectProps={{
              MenuProps: {
                transitionDuration: 0,
              },
            }}
            label="status"
            value={selectedColumn === 'Status' ? selectedStatus : selectedCallType}
            onChange={handleStatusChange}
            variant="standard"
            fullWidth
          >
            {selectedColumn === 'Status' ? statusSelect : callTypeSelect}
          </TextField>
        }
      </Grid>
      <Grid item xs={1} sx={{mt: 1.5}}>
        {filterArr.length > 1 && <DeleteButton onClick={handleDeleteFilter} />}
      </Grid>
    </>
  )
}

LogsMultiFilterItem.propTypes = {
  id: PropTypes.number,
  row: PropTypes.object,
  length: PropTypes.number,
  setFilter: PropTypes.func,
  filterArr: PropTypes.array
}
