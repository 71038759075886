import React, {useEffect, useState} from 'react';
import {Paper, TextField} from '@mui/material';
import {StyledButton} from '../../styleditems';
import Spinner from '../Spinner';
import {toast, Toaster} from 'react-hot-toast';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {passwordValidationSchema} from '../../constants/passwordValidationSchema';
import {styled} from '@mui/styles';
import {useHistory, useLocation} from 'react-router-dom';
import {resetPassword} from '../../services';

const CustomPaper = styled(Paper)({
  maxWidth: '300px',
  minHeight: "400px",
  margin: "20px auto",
  display: "flex",
  padding: "20px",
  flexDirection: "column",
  justifyContent: "space-around"
})

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const token = new URLSearchParams(useLocation().search).get("reset_password_token");
  const history = useHistory()

  const defaultFieldValues = {password: '', confirm_password: ''};
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors, isSubmitSuccessful}
  } = useForm({
    resolver: yupResolver(passwordValidationSchema),
    defaultValues: defaultFieldValues
  });


  const onSubmit = async ({password, confirm_password}) => {
    try {
      setLoading(true);
      await resetPassword({password, confirm_password, token});
      setLoading(false);
      history.push('/login');
    } catch (error) {
      setLoading(false);
      history.push('/login');
      toast.error("Try again");
    }
  }
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultFieldValues);
    }
  }, [isSubmitSuccessful]);

  if (loading) return <Spinner />

  return (
    <CustomPaper elevation={12} sx={{}}>
      <Typography component="h1" variant="h5" align="center">
        Reset password
      </Typography>
      <Box>
        <TextField
          required
          id="password"
          name="password"
          label="Password"
          type="password"
          defaultValue=""
          fullWidth
          margin="dense"
          {...register("password")}
          error={!!errors["password"]}
        />
        <Typography variant="inherit" color="textSecondary">
          {errors["password"]?.message}
        </Typography>
      </Box>
      <Box>
        <TextField
          required
          id="confirm_password"
          name="confirm_password"
          label="Confirm password"
          type="password"
          defaultValue=""
          fullWidth
          margin="dense"
          {...register("confirm_password")}
          error={!!errors["confirm_password"]}
        />
        <Typography variant="inherit" color="textSecondary">
          {errors["confirm_password"]?.message}
        </Typography>
      </Box>
      <StyledButton onClick={handleSubmit(onSubmit)} sx={{mt: 2, mb: 2, maxHeight: "unset"}}>Reset password</StyledButton>
      <Toaster />
    </CustomPaper>
  )
}
