import React from 'react';
import {NavLink} from 'react-router-dom';
import {StyledBodyCell} from '../../../styleditems';

export default function SkuCell({column, row}) {
  return (
    <StyledBodyCell key={column.field}>
      <NavLink
        to={{pathname: `/products/${row.id}`}}
        target="_blank"
        style={{color: "#53A551"}}
      >
        {row[column.field]}
      </NavLink>
    </StyledBodyCell>
  );
}
