import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useFetchData} from '../../hooks';
import {getPriceHistory} from '../../services';
import {priceHistoryAtom} from '../../state';
import Spinner from '../Spinner';
import NotFound from '../../pages/NotFound';
import {chartsColors} from '../../constants/chartsColors';
import Title from '../Title';
import CustomDateFilter from './CustomDateFilter';
import Toolbar from '@mui/material/Toolbar';
import {suppliersTitles} from '../../constants/suppliersTitles';
import moment from 'moment';
import {StyledButton} from '../../styleditems';
import {styled} from '@mui/styles';
import {useSetRecoilState} from 'recoil';
import PropTypes from 'prop-types';


export const DateSelectButton = styled(StyledButton)({
  marginLeft: "2px",
})

export default function PeriodicCharts({id}) {
  const {isLoading, isError, fetchedData} = useFetchData(getPriceHistory, priceHistoryAtom, id)
  const [selectedLine, setSelectedLine] = useState([]);
  const setPriceHistory = useSetRecoilState(priceHistoryAtom);

  if (isLoading) return <Spinner />;
  if (isError) return <NotFound />;
  if (fetchedData.length === 0) return null;

  const chartLines = suppliersTitles.map((item, index) => {
    return (<Line
      dataKey={item.name}
      key={index}
      name={item.name}
      isAnimationActive={true}
      type="monotone"
      stroke={chartsColors[index]}
      dot={true}
      hide={selectedLine.includes(item.name)}
    />)
  })

  const handleLegendClick = (e) => {
    const {dataKey} = e;
    if (!selectedLine.includes(dataKey)) {
      setSelectedLine([...selectedLine, dataKey]);
    } else {
      const restArr = selectedLine.filter(item => item !== dataKey)
      setSelectedLine(restArr);
    }
  }
  const formatXAxis = tickItem => {
    return moment.unix(tickItem).format('MM/DD/YYYY');
  }

  const handleFilter = async (days) => {
    try {
      const {data} = await getPriceHistory(
        id,
        moment().subtract(days, 'days').format('MM/DD/YYYY'),
        moment().format('MM/DD/YYYY')
      );
      if (data.data.length === 0) {
        toast.error("There is no data in selected range. Select another range");
        return null;
      }
      setPriceHistory(data.data);
    } catch (e) {
      console.log(e)
    }
  }

  return (<Paper
    elevation={6}
    sx={{
      position: "relative",
      p: 2,
      '.recharts-legend-wrapper': {
        left: "30px!important", width: "auto!important", padding: '10px 40px'
      },
      '.recharts-legend-item-text': {
        cursor: 'pointer'
      }
    }}>
    <Title>Periodic Chart</Title>
    <Toolbar sx={{
      justifyContent: "flex-end",
      mr: 4,
      flexWrap: 'wrap'
    }}>
      <DateSelectButton onClick={() => handleFilter(7)}>Last 7 days</DateSelectButton>
      <DateSelectButton onClick={() => handleFilter(30)}>Last 30 days</DateSelectButton>
      <DateSelectButton onClick={() => handleFilter(90)}>Last 90 days</DateSelectButton>
      <DateSelectButton onClick={() => handleFilter(180)}>Last 180 days</DateSelectButton>
      <CustomDateFilter id={id} />
    </Toolbar>
    <ResponsiveContainer width="100%" height={600}>
      <LineChart
        data={fetchedData}
        margin={{right: "60"}}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" type="number" tickFormatter={formatXAxis} domain={['dataMin', 'dataMax']} />
        <YAxis type="number" />
        <Tooltip labelFormatter={value => moment.unix(value).format('MM/DD/YYYY')} />
        <Legend
          verticalAlign="top"
          onClick={handleLegendClick}
          margin={{bottom: 16}}
        />
        {chartLines}
      </LineChart>
    </ResponsiveContainer>
  </Paper>);
}

PeriodicCharts.propTypes = {
  id: PropTypes.string
}
