import React, {useState} from 'react';
import {useOpenCloseDialog} from '../../hooks';
import Button from '@mui/material/Button';
import {Dialog, DialogActions, DialogContent, DialogContentText, TextField} from '@mui/material';
import CustomDialogTitle from '../CustomDialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import {editShippingPolicy} from '../../services/competitors.service';
import {useRecoilState} from 'recoil';
import {competitorsListAtom} from '../../state';

export default function EditShippingPolicy({id}) {
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [shippingPolicy, setShippingPolicy] = useRecoilState(competitorsListAtom);
  const {shipping_policy} = shippingPolicy.find(item=>item.id===id);
  const [currentValue, setCurrentValue] = useState(shipping_policy);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
  }

  const handleSave = async () => {
    try {
      const {data} = await editShippingPolicy({shipping_policy: currentValue, id: id});
      setShippingPolicy(prevState => prevState.map((item) => item.id === data.data.id ? data.data : item));
      setCurrentValue('');
      handleClose();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <IconButton
        aria-label="edit"
        color="success"
        onClick={handleOpen}
      >
        <EditIcon />
      </IconButton>
      <Dialog open={isOpen} onClose={handleClose}>
        <CustomDialogTitle>Edit Shipping Policy</CustomDialogTitle>
        <DialogContent sx={{minWidth: "500px"}}>
          <DialogContentText>
            Enter new Shipping Policy
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="shipping_policy"
            label="Shipping Policy"
            type="text"
            fullWidth
            variant="standard"
            value={currentValue}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
