import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import {NavLink} from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import {makeUpperCase} from '../../helpers';
import PropTypes from 'prop-types';

export default function CustomMenuItem({text, route, alignment}) {
  return (
    <MenuItem
      key={text}
      component={NavLink}
      to={route}
      sx={{
        maxWidth: "150px",
        textAlign: `${alignment}`,
        ".MuiListItemText-primary": {fontWeight: 700},
        color: "#303D4E"
      }}>
      <ListItemText
        primary={makeUpperCase(text)}
      />
    </MenuItem>
  );
}

CustomMenuItem.propTypes = {
  text: PropTypes.string,
  route: PropTypes.string,
  alignment: PropTypes.string
}
