import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function Title(props) {
  return (
    <Typography
      component="h2"
      variant="h6"
      align="center"
      color="#555"
      fontWeight="700"
    >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  props: PropTypes.string
}
