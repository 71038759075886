import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {StyledBodyCell} from '../../../styleditems';

export default function TagsCell({column, row}) {
  const tagsCell = row['tags'].map(tag => tag['name']);
  return (
    <Tooltip title={tagsCell.join(", ")} key="tags">
      <StyledBodyCell
        sx={{
          maxWidth: "220px",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {tagsCell.join(", ")}
      </StyledBodyCell>
    </Tooltip>
  )
}
