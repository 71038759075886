import React from 'react';
import {StyledBodyCell} from '../../../styleditems';

export default function PSCheapestCell({column, row}) {
  let cellField = ''
  if (row[column.field]) {
    cellField = row[column.field].includes('-') ? row[column.field] : ("+" + row[column.field]);
  }
  return (
    <StyledBodyCell key={column.field}>
      {cellField}
    </StyledBodyCell>
  );
}
