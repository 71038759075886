import React, {useState} from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import {useRecoilState, useRecoilValue} from 'recoil';
import {
  competitorsListAtom,
  filterStateAtom,
  orderStateAtom,
  productsListAtom,
  rowsPerPageAtom,
  sortFieldAtom,
  visibleColumnsState
} from '../../state';
import ProductsTableHead from './ProductsTableHead';
import ProductsTableBody from './ProductsTableBody';
import {getAll} from '../../services';
import {getFilterData, setPlainData} from '../../helpers';
import NotFound from '../../pages/NotFound';
import Spinner from '../Spinner';
import RowsPerPageSelect from './RowsPerPageSelect';
import ProductsListPagination from './ProductsListPagination';
import {fixedProductColumnHeaders} from '../../constants/fixedProductColumnHeaders';
import {useFetchData} from '../../hooks';
import {getCompetitors} from '../../services/competitors.service';

export default function ProductsList() {
  const {fetchedData: competitors} = useFetchData(getCompetitors, competitorsListAtom);
  const [data, setData] = useRecoilState(productsListAtom);
  const columns = useRecoilValue(visibleColumnsState);
  const {rows, total_pages, current_page} = data;
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageAtom);
  const order = useRecoilValue(orderStateAtom);
  const sortField = useRecoilValue(sortFieldAtom);
  const filter = useRecoilValue(filterStateAtom);
  const filterData = getFilterData(filter);

  const handleRowsPerPageChange = async (event) => {
    setLoading(true);
    try {
      const response = await getAll({
        pageNum: 1,
        perPage: event.target.value,
        direction: order,
        sort: sortField,
        filteredData: filterData
      });
      const {data} = response.data;
      setData(prevState => {
          const {rows} = setPlainData(data);
          return {...prevState, rows: rows, current_page: data['current_page'], total_pages: data['total_pages']}
        }
      );
      setRowsPerPage(event.target.value);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const handleChange = async (event, value) => {
    setLoading(true);
    try {
      const response = await getAll({
        pageNum: value,
        perPage: rowsPerPage,
        direction: order,
        sort: sortField,
        filteredData: filterData
      });
      const {data} = response.data;
      setData(prevState => {
          const {rows} = setPlainData(data);
          return {...prevState, rows: rows, current_page: data['current_page']}
        }
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  }

  if (isLoading) return <Spinner />;
  if (isError) return <NotFound />;

  return (
    <>
      <TableContainer sx={{height: "73vh"}}>
        <Table stickyHeader aria-label="sticky table">
          <ProductsTableHead
            columns={[...fixedProductColumnHeaders, ...columns, {
              "field": "actions",
              "fieldName": "",
              "visible": true
            }]}
            onLoading={setLoading}
            competitors={competitors}
          />
          <ProductsTableBody rows={rows}
                             columns={[...fixedProductColumnHeaders, ...columns, {
                               "field": "actions",
                               "fieldName": "",
                               "visible": true
                             }]}
                             competitors={competitors}
          />
        </Table>
      </TableContainer>
      <div style={{display: "flex", justifyContent: "flex-end", marginRight: "20px"}}>
        <RowsPerPageSelect rowsPerPage={rowsPerPage} handleRowsPerPageChange={handleRowsPerPageChange} />
        <ProductsListPagination total_pages={total_pages} current_page={current_page} handleChange={handleChange} />
      </div>
    </>
  );
}
