import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import {FormControl, MenuItem, Select} from '@mui/material';
import {editStatus} from '../../services';
import {useSetRecoilState} from 'recoil';
import {oneProductAtom} from '../../state';
import PropTypes from 'prop-types';

export default function EditStatusCell({id, productStatus}) {
  const [isEdit, setEdit] = useState(false);
  const [status, setStatus] = useState(productStatus);
  const setProduct = useSetRecoilState(oneProductAtom);

  const handleSelect = (event) => {
    setStatus(event.target.value);
  };

  const handleEdit = () => {
    setEdit(prevState => !prevState);
  }

  const handleSave = async () => {
    try {
      const {data} = await editStatus(id, status.toLowerCase());
      await setProduct(prevState=>({...prevState, status: data.data.status}))
      await setEdit(prevState => !prevState);
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {isEdit ?
        <>
          <FormControl variant="standard">
            <Select
              value={status}
              onChange={handleSelect}
              label="Status"
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Archived">Archived</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            aria-label="done"
            color="success"
            size="small"
            onClick={handleSave}
          >
            <DoneIcon />
          </IconButton>
        </> :
        <>
          {status}
          <IconButton
            aria-label="edit"
            color="success"
            size="small"
            onClick={handleEdit}
          >
            <EditIcon />
          </IconButton>
        </>
      }
    </Box>
  );
}

EditStatusCell.propTypes = {
  id: PropTypes.number,
  productStatus: PropTypes.string
}
