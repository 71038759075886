import React, {useState} from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import {useOpenCloseDialog} from '../../hooks';
import CustomDialogTitle from '../CustomDialogTitle';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {toast, Toaster} from 'react-hot-toast';
import {addSKU} from '../../services';
import MiniLoader from '../MiniLoader';

const Input = styled('input')({
  display: 'none',
});

export default function UploadCSV() {
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [file, setFile] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isSelected, setSelected] = useState(false);

  const handleChange = (e) => {
    if (e.target.files[0].type === "text/csv") {
      setFile(e.target.files[0]);
      setSelected(true);
    } else {
      toast.error('You can select only .csv file');
      return null;
    }
  }

  const handleUpload = async () => {
    try {
      setLoading(true)
      handleClose();
      const {data} = await addSKU({file: file});
      toast.success(data.message);
      setFile(null);
      setSelected(false)

      setLoading(false)
    } catch (e) {
      handleClose();
      setLoading(false)
      console.log(e);
    }
  }

  return (
    <>
      <Button onClick={handleOpen}>
        <UploadFileIcon />
        {isLoading ? 'Uploading...' : 'Import SKUs'}
        {isLoading && <MiniLoader />}
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <CustomDialogTitle>Upload File</CustomDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 1,
            minWidth: "400px"
          }}
        >
          <label htmlFor="icon-button-file">{isSelected ? `Filename: ${file?.name}` : `Select a file`}
            <Input accept=".csv" id="icon-button-file" type="file" onChange={handleChange} />
            <Button color="primary" aria-label="upload csv" component="span">
              <CloudUploadIcon fontSize="large" />
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpload}>Create products</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Toaster />
    </>
  );
}
