import {productFilterColumns} from '../constants/productFilterColumns';

export const getFilterData=(filter)=>filter.map(({column, operator, value}) => {
    if (column !== '' && operator !== '' && value !== '') return ({
      column: productFilterColumns[`${column}`],
      operator,
      value
    })
  }
)
