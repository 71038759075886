export const productDetailsColumns = [
  "name",
  "sku",
  "status",
  "brand.title",
  "manufacturer",
  "upc_code",
  "model_number",
  "asin_number",
  "walmart_id",
  "tags",
  "ps_price",
  "min_price_diff_doll",
  "min_price_diff_perc",
  "part_number",
  "pack_upc_code",
  "manufacturer_case_pack",
  "vendor_number",
  "pdp_family_id",
  "ps_url",
  "pss_url",

]
