import React, {useState} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import {MenuItem, Paper, Select} from '@mui/material';
import {toast} from 'react-hot-toast';
import {getAll} from '../../services';
import {useSetRecoilState, useRecoilValue} from 'recoil';
import {filterStateAtom, productsListAtom, rowsPerPageAtom} from '../../state';
import {setPlainData} from '../../helpers';
import uniqueId from 'lodash/uniqueId';
import {makeStyles} from '@mui/styles';
import MiniLoader from '../MiniLoader';
import {styled} from '@mui/material/styles';
import {useHistory} from 'react-router-dom';

const searchFields = {
  "sku": "SKU",
  "model_number": "Model #",
  "products.name": "Product Title",
  "manufacturer": "Manufacturer",
  "vendor_number": "Vendor #",
  "upc_code": "UPC"
}

const useStyles = makeStyles({
  root: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff"
    }
  }
});

const CustomForm = styled('form')({
  display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
})

export default function SearchPanel() {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [filterItem, setFilterItem] = useState('sku');
  const [searchString, setSearchString] = useState('');
  const rowsPerPage = useRecoilValue(rowsPerPageAtom);
  const setFilter = useSetRecoilState(filterStateAtom);
  const setFilteredData = useSetRecoilState(productsListAtom);
  const classes = useStyles();

  const handleChange = (event) => {
    setSearchString(event.target.value);
  };
  const handleSelect = (event) => {
    setFilterItem(event.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const filterData = [{column: filterItem, operator: "contains", value: searchString.trim()}]
      const response = await getAll({perPage: rowsPerPage, filteredData: filterData});
      const {data} = response.data;
      setFilteredData(_ => {
          const {rows} = setPlainData(data);
          return {rows: rows, current_page: data['current_page'], total_pages: data['total_pages']}
        }
      );

      setFilter([{
        id: +uniqueId(),
        column: searchFields[filterItem],
        operator: "contains",
        value: searchString,
        dataType: "string"
      }]);
      setSearchString('');
      setFilterItem('sku');
      history.push('/');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.response.statusText);
    }
  }

  return (
    <Paper
      sx={{p: '2px 4px', mr: 2, width: 400}}
    >
      <CustomForm onSubmit={handleSearch}>
        <Select
          variant="standard"
          sx={{pl: 1, minWidth: "140px"}}
          value={filterItem}
          onChange={handleSelect}
          disableUnderline
          className={classes.root}
        >
          {Object.keys(searchFields).map(item=>(<MenuItem value={item}>{searchFields[item]}</MenuItem>))}
        </Select>
        <InputBase
          sx={{ml: 1, flex: 1}}
          placeholder="Search"
          inputProps={{'aria-label': 'search'}}
          value={searchString}
          onChange={handleChange}
        />
        <IconButton
          sx={{p: '10px', color: "#53A551"}}
          aria-label="search"
          disabled={!searchString}
          type="submit"
        >
          <SearchIcon />
          {isLoading && <MiniLoader />}
        </IconButton>
      </CustomForm>
    </Paper>
  );
}
