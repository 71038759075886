export const getFullProductsHeader = (productsHeader) => {
  const fullProductsHeader = [];
  for (let i = 0; i < productsHeader.length; i++) {
    if (productsHeader[i].field === 'product_suppliers') {
      fullProductsHeader.push(productsHeader[i]);
      fullProductsHeader.push({
        "field": "product_suppliers",
        "fieldName": `${productsHeader[i].fieldName} Shipping Cost`,
        "visible": true,
        "slug": `${productsHeader[i].slug}`,
        "type": "number"
      })
    } else {
      fullProductsHeader.push(productsHeader[i]);
    }
  }
  return fullProductsHeader;
}
