import React, {useState} from 'react';
import {TableBody} from '@mui/material';
import PropTypes from 'prop-types';
import ProductsTableRow from './ProductsTableRow';

export default function ProductsTableBody({rows, columns, competitors}) {
  const [selected, setSelected] = useState();

  const setSelectedID = (id) => {
    selected !== id ? setSelected(id) : setSelected();
  }

  const tableBodyRows = rows.map(row =>
    <ProductsTableRow
      key={row.id}
      row={row}
      columns={columns}
      selectedID={selected}
      setSelectedID={setSelectedID}
      competitors={competitors}
    />
  )

  return (
    <TableBody>
      {tableBodyRows}
    </TableBody>
  );
}

ProductsTableBody.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array
}
