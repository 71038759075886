import React from 'react';
import {StyledBodyCell, StyledTableRow} from '../../../styleditems';

export default function BrandTitleCell({item, data}) {
  return (
    <StyledTableRow key={item} sx={{height: "31px"}}>
      <StyledBodyCell key={item}>
        BRAND:
      </StyledBodyCell>
      <StyledBodyCell key="brand.title.value">
        {data["brand"] ? data["brand"]["title"] : null}
      </StyledBodyCell>
    </StyledTableRow>
  )
}
