import React from 'react';
import {DialogTitle} from '@mui/material';
import PropTypes from 'prop-types';

export default function CustomDialogTitle(props) {
  return (
    <DialogTitle sx={{textAlign: 'center'}}>{props.children}</DialogTitle>
  );
}

CustomDialogTitle.propTypes = {
  props: PropTypes.string
}

