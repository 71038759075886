import React from 'react';
import {suppliersPricesHeaders} from '../../constants/suppliersPricesHeaders';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableBody} from '@mui/material';
import Paper from '@mui/material/Paper';
import {priceStatus} from '../../constants/priceStatus';
import {StyledHeaderCell, StyledTableRow, SuppliersStyledBodyCell} from '../../styleditems';
import PropTypes from 'prop-types';
import EditSuppliersURL from '../EditSuppliersURL';
import PriceStatusLegend from './PriceStatusLegend';
import LastCheckedCell from './SuppliersPricesCells/LastCheckedCell';
import UrlVerifiedCell from './SuppliersPricesCells/UrlVerifiedCell';
import UpdatedAtCell from './SuppliersPricesCells/UpdatedAtCell';
import SupplierNameCell from './SuppliersPricesCells/SupplierNameCell';

export default function SuppliersPrices({data}) {
  const {role} = JSON.parse(localStorage.getItem('user'));
  const columnsHeaders = suppliersPricesHeaders.map(({headerName}) => (
    <StyledHeaderCell key={headerName} sx={{minWidth: "50px!important", pl: 1, pr: 1}}>
      {headerName}
    </StyledHeaderCell>))
  const amazon = data['product_suppliers'].find(item => item.slug === 'amazon');
  const walmart = data['product_suppliers'].find(item => item.slug === 'walmart');
  const rows = [...data['product_suppliers']].sort((a, b) => a.id - b.id).map((row) => {
    return (
      <StyledTableRow
        key={row.id}
        sx={{height: "31px", 'td': {color: priceStatus[row['status']]}}}
      >
        {suppliersPricesHeaders.map(({field}) => {
            if (field === 'last_checked') return <LastCheckedCell field={field} row={row} />
            if (field === 'url_verified') return <UrlVerifiedCell field={field} row={row} />
            if (field === 'updated_at') return <UpdatedAtCell field={field} row={row} />
            if (field === 'supplier_name') return <SupplierNameCell field={field} row={row} amazon={amazon}
                                                                    walmart={walmart} />
            return (
              <SuppliersStyledBodyCell key={field}>
                {row[field] === null || typeof row[field] === 'object' ? '' : row[field]}
              </SuppliersStyledBodyCell>
            )
          }
        )}
        {role === 'Admin' &&
          <SuppliersStyledBodyCell key="actionButton">
            {!(/amazon-/.test(row.slug) || /walmart-/.test(row.slug)) && <EditSuppliersURL supplierData={row} />}
          </SuppliersStyledBodyCell>
        }
      </StyledTableRow>
    )
  });

  return (
    <TableContainer
      component={Paper}
      elevation={6}
      sx={{minHeight: "700px", display: "flex", flexDirection: "column", justifyContent: "space-between"}}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columnsHeaders}
            {role === 'Admin' &&
              <StyledHeaderCell key="action" sx={{minWidth: "40px!important"}}>
                Edit
              </StyledHeaderCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
      <PriceStatusLegend />
    </TableContainer>
  );
}

SuppliersPrices.propTypes = {
  data: PropTypes.object
}
