import React from 'react';
import {styled} from '@mui/styles';
import MultiFilter from '../MultiFilter';
import SaveLayout from '../SaveLayout';
import LoadLayout from '../LoadLayout';
import ResetLayout from '../ResetLayout';
import ColumnsView from '../ColumnsView';
import AddSKU from '../AddSKU';
import UploadCSV from '../UploadCSV';
import ExportCSV from '../ExportCSV';
import ShowShipping from '../ShowShipping';

const ToolbarWrapper = styled("div")({
  padding: "5px",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
})

export default function ProductsToolbar() {
  return (
    <ToolbarWrapper>
      <ColumnsView />
      <MultiFilter />
      <AddSKU />
      <UploadCSV />
      <SaveLayout />
      <LoadLayout />
      <ResetLayout />
      <ExportCSV />
      <ShowShipping />
    </ToolbarWrapper>
  );
}
