import React from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import HeadBar from '../../components/HeadBar';
import SignIn from '../SignIn';
import {useRecoilValue} from 'recoil';
import {authAtom} from '../../state';
import MuiBox from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Route, Switch} from 'react-router-dom';
import Users from '../Users';
import Suppliers from '../Suppliers';
import Prices from '../Prices';
import Products from '../Products';
import {PrivateRoute} from '../../routes/PrivateRoute';
import ResetPassword from '../../components/ResetPassword';
import NotFound from '../NotFound';
import TestComponent from '../../components/TestComponent';

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#53A551",
    }
  },
  typography: {
    color: '#303D4E',
    fontFamily: [
      'open sans',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "#53A551"
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "#303D4E",
        },
      },
    }
  },
})

const MainBox = styled(MuiBox)(() => ({
  overflow: "hidden",
  paddingTop: "5px",
  paddingBottom: "5px",
  backgroundColor: "#F6F8FB",
  display: "flex",
  flexGrow: 1,
  ".MuiContainer-root": {
    backgroundColor: "#F6F8FB",
    overflow: "hidden"
  }
}))


export default function Dashboard() {
  const auth = useRecoilValue(authAtom);
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflow: "hidden",
        justifyContent: "space-between"
      }}>
        {auth && <HeadBar />}
        <MainBox component="main" sx={{pb: 0}}>
          <Container maxWidth="100vw">
            <Switch>
              <Route path="/login">
                <SignIn />
              </Route>
              <Route path="/resetpassword:reset_password_token?">
                <ResetPassword />
              </Route>
              <PrivateRoute exact path="/users" component={Users} />
              {auth?.role === 'Admin' && <PrivateRoute exact path="/competitors" component={Suppliers} />}
              <PrivateRoute exact path="/products/:id" component={Prices} />
              <PrivateRoute exact path="/logs" component={TestComponent} />
              <PrivateRoute exact path="/" component={Products} />
              <PrivateRoute path="*" component={NotFound} />
            </Switch>
          </Container>
        </MainBox>
      </Box>
    </ThemeProvider>
  );
}

