import * as Yup from 'yup';

export const userValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid'),
  first_name: Yup.string()
    .required('First name is required')
    .min(1, 'First name must be at least 1 characters')
    .max(20, 'First name must not exceed 20 characters'),
  last_name: Yup.string()
    .required('Last name is required')
    .min(1, 'Last name must be at least 1 characters')
    .max(20, 'Last name must not exceed 20 characters'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
  confirm_password: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Confirm Password does not match')
});
