import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import React from "react";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupIcon from '@mui/icons-material/Group';

export const menuRoutes = [
  {menuIcon: <ShoppingCartIcon />, route: "/", text: "Products"},
  {menuIcon: <ManageAccountsIcon />, route: "/users", text: "Users"},
  {menuIcon: <GroupIcon />, route: "/competitors", text: "Competitors"}
]
