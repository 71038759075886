import {useSetRecoilState} from 'recoil';
import {columnsViewAtom, filterStateAtom, layoutsListAtom, productsHeadersAtom, selectedLayoutAtom} from '../state';

const useSetLayouts = () => {
  const setColumnHeaders = useSetRecoilState(productsHeadersAtom);
  const setFilterState = useSetRecoilState(filterStateAtom);
  const setColumnsView = useSetRecoilState(columnsViewAtom);
  const setLayouts = useSetRecoilState(layoutsListAtom);
  const setSelectedLayout = useSetRecoilState(selectedLayoutAtom);
  return {setColumnHeaders, setFilterState, setLayouts, setColumnsView, setSelectedLayout};
}

export {useSetLayouts}
