import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import {SuppliersStyledBodyCell} from '../../../styleditems';

export default function UrlVerifiedCell({field, row}) {
  return (
    <SuppliersStyledBodyCell key={field}>
      {row[field] && <CheckIcon />}
    </SuppliersStyledBodyCell>
  )
}
