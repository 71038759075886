import React from 'react';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import {StyledBodyCell} from '../../../styleditems';

export default function PsUrlCell({column, row}) {
  return (
    <StyledBodyCell key={column.field}>
      {row['ps_url'] ? <Link href={row['ps_url']} target="_blank"><LinkIcon /></Link> : ''}
    </StyledBodyCell>
  );
}
