import React, {useState} from 'react';
import {Stack, TableBody} from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import {StyledBodyCell, StyledButton, StyledTableRow} from '../../styleditems';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {productDetailsColumns} from '../../constants/productDetailsColumns';
import BrandTitleCell from './ProductDetailsCells/BrandTitleCell';
import TagsCell from './ProductDetailsCells/TagsCell';
import NameCell from './ProductDetailsCells/NameCell';
import PSUrlCell from './ProductDetailsCells/PSUrlCell';

export default function ProductDetails({data}) {
  const [isExtended, setExtended] = useState(false);

  const mainDetailsRows = productDetailsColumns.map((item) => {
    if (item === 'brand.title') return <BrandTitleCell item={item} data={data} />
    if (item === 'tags') return <TagsCell item={item} data={data} />
    if (item === 'name') return <NameCell item={item} data={data} />
    if (typeof data[item] !== 'object' && item !== 'id') return <PSUrlCell item={item} data={data} />
  });

  const additionalDetailsRows = Object.keys(data['product_detail']).map((item) => {
    if (item !== 'id') {
      return (
        <StyledTableRow key={item} sx={{height: "31px"}}>
          <StyledBodyCell key={item}>
            {item.toUpperCase().replaceAll('_', ' ')}:
          </StyledBodyCell>
          <StyledBodyCell key={data['product_detail'][item]}>
            {data['product_detail'][item]}
          </StyledBodyCell>
        </StyledTableRow>
      )
    }
  });

  const [rows, setRows] = useState(mainDetailsRows);

  const handleClick = () => {
    if (!isExtended) {
      setRows(prevState => [...prevState, additionalDetailsRows]);
      setExtended(true);
    } else {
      setRows(mainDetailsRows);
      setExtended(false);
    }
  }

  return (
    <TableContainer component={Paper} elevation={6} sx={{minHeight: "700px"}}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{minHeight: "54px", pl: 1, pr: 1}}
      >
        <Typography
          id="tableTitle"
          component="div"
          sx={{fontWeight: 700, pl: 1}}
        >
          Product Details
        </Typography>
        {!isExtended ?
          <StyledButton onClick={handleClick}>
            Show Details
          </StyledButton> :
          <StyledButton onClick={handleClick}>
            Hide Details
          </StyledButton>}
      </Stack>
      <Table aria-label="simple table">
        <TableBody>
          {rows}
        </TableBody>
      </Table>

    </TableContainer>
  );
}

ProductDetails.propTypes = {
  data: PropTypes.object
}
