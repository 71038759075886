import React from 'react';
import {StyledBodyCell, StyledProductTableRow} from '../../styleditems';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import {showShippingAtom} from '../../state';
import SkuCell from './TableBodyCell/SkuCell';
import ProductTitleCell from './TableBodyCell/ProductTitleCell';
import PsPriceCell from './TableBodyCell/PsPriceCell';
import PsUrlCell from './TableBodyCell/PsUrlCell';
import PssUrlCell from './TableBodyCell/PssUrlCell';
import ProductSuppliersCell from './TableBodyCell/ProductSuppliersCell';
import UpdatedAtCell from './TableBodyCell/UpdatedAtCell';
import TagsCell from './TableBodyCell/TagsCell';
import ActionsCell from './TableBodyCell/ActionsCell';
import PSCheapestCell from './TableBodyCell/PSCheapestCell';

export default function ProductsTableRow({row, columns, selectedID, setSelectedID, competitors}) {
  const showPricing = useRecoilValue(showShippingAtom);

  const handleClick = () => {
    setSelectedID(row.id);
  }

  return (
    <StyledProductTableRow
      selected={row.id === selectedID}
      onClick={handleClick}
    >
      {
        columns.map(column => {
          if (column.field === 'sku') return <SkuCell row={row} column={column} />
          if (column.field === 'name') return <ProductTitleCell column={column} row={row} />
          if (column.field === 'ps_price') return <PsPriceCell row={row} column={column} />
          if (column.field === 'ps_price') return <PsPriceCell row={row} column={column} />
          if (column.field === 'ps_url') return <PsUrlCell column={column} row={row} />
          if (column.field === 'pss_url') return <PssUrlCell column={column} row={row} />
          if (column.field === 'min_price_diff_doll') return <PSCheapestCell column={column} row={row} />
          if (column.field === 'product_suppliers') return <ProductSuppliersCell row={row} column={column}
                                                                                 competitors={competitors}
                                                                                 showPricing={showPricing} />
          if (column.field === 'updated_at') return <UpdatedAtCell column={column} row={row} />
          if (column.field === 'tags') return <TagsCell row={row} column={column} />
          if (column.field === 'actions') return <ActionsCell column={column} row={row} />
          return (
            <StyledBodyCell key={column.fieldName}>
              {row[column.field]}
            </StyledBodyCell>
          )
        })
      }
    </StyledProductTableRow>)
}

ProductsTableRow.propTypes = {
  row: PropTypes.object,
  columns: PropTypes.array,
  selectedID: PropTypes.number,
  setSelectedID: PropTypes.func,
  competitors: PropTypes.array
}

