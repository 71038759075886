export const suppliersHeaders = [
  {
    field: 'active',
    headerName: 'Active',
  },
  {
    field: 'title',
    headerName: 'Competitor',
  },
  {
    field: 'identify',
    headerName: 'Identify'
  },
  {
    field: 'seller_type',
    headerName: 'Seller Type',
  },
  {
    field: 'shipping_policy',
    headerName: 'Shipping Policy',
  }
]
