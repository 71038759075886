import {API_URL} from '../constants/railsAPIURL';
import axios from 'axios';

const baseUrl = `${API_URL}users`;

export function getAllUsers() {
  return axios.get(`${baseUrl}`)
}

export function getOneUser(id) {
  return axios.get(`${baseUrl}/${id}`)
}

export function editOneUser({id, email, first_name, last_name, password, confirm_password, role}) {
  let user = new FormData();
  user.append("user[email]", email);
  user.append("user[first_name]", first_name);
  user.append("user[last_name]", last_name);
  if (password) {
    user.append("user[password]", password);
    user.append("user[password_confirmation]", confirm_password);
  }
  user.append("user[role]", role);
  return axios.put(`${baseUrl}/${id}`, user);
}

export function addUser({email, first_name, last_name, password, confirm_password, role}) {
  let data = new FormData();
  data.append("user[email]", email);
  data.append("user[first_name]", first_name);
  data.append("user[last_name]", last_name);
  data.append("user[password]", password);
  data.append("user[password_confirmation]", confirm_password);
  data.append("user[role]", role);
  return axios.post(`${baseUrl}`, data);
}

export function deleteOneUser(id) {
  return axios.delete(`${baseUrl}/${id}`);
}
