import React from 'react';
import {FormControl, FormHelperText, MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';

export default function RowsPerPageSelect({rowsPerPage, handleRowsPerPageChange}) {
  return (
    <FormControl sx={{minWidth: 80, maxWidth: 100, mr: 1, mt: 3}}>
      <Select
        variant="standard"
        value={rowsPerPage}
        onChange={handleRowsPerPageChange}
        sx={{textAlign: "center"}}
      >
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
      <FormHelperText>RowsPerPage</FormHelperText>
    </FormControl>
  );
}

RowsPerPageSelect.propTypes = {
  rowsPerPage: PropTypes.number,
  handleRowsPerPageChange: PropTypes.func
}
