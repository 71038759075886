import React, {useState} from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableBody, TableCell} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {addUser, deleteOneUser, editOneUser} from '../../services';
import {useSetRecoilState} from 'recoil';
import {authAtom, usersAtom} from '../../state';
import DeleteIcon from '@mui/icons-material/Delete';
import AddUser from '../AddUser';
import {toast, Toaster} from 'react-hot-toast';
import {StyledBodyCell, StyledHeaderCell, StyledTableRow} from '../../styleditems';
import PropTypes from 'prop-types';
import EditUser from '../EditUser/EditUser';
import Spinner from '../Spinner';

export default function UsersList({data}) {
  const [isLoading, setLoading] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const [rows, setRows] = useState(data);
  const columns = Object.keys(data[0]);
  const setUsers = useSetRecoilState(usersAtom);
  const setCurrentUser = useSetRecoilState(authAtom);

  const handleEditUser = async (newUser) => {
    try {
      if (newUser.password.length > 0 && newUser.password.length < 6) {
        toast.error("Password must be at least 6 characters");
        return null;
      }
      const {data} = await editOneUser(newUser);
      setUsers(prevState => prevState.map(item => item.id === data.data.id ? data.data : item));
      setRows(prevState => prevState.map(item => item.id === data.data.id ? data.data : item));
      if (currentUser.id === data.data.id) {
        localStorage.setItem("user", JSON.stringify(data.data));
        setCurrentUser(data.data);
      }
    } catch (e) {
      toast.error("Some error")
    }
  }

  const handleAddUser = async (newUser) => {
    try {
      setLoading(true)
      const {data} = await addUser(newUser);
      setUsers(prevState => [...prevState, data.data]);
      setRows(prevState => [...prevState, data.data]);
      setLoading(false)
    } catch (error) {
      toast.error(error.response.data.error)
      setLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      if (window.confirm('Would you like to delete current user?')) {
        setLoading(true)
        await deleteOneUser(id);
        setRows(state => state.filter(row => row.id !== id))
        setLoading(false)
      }
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  if (isLoading) return <Spinner />

  return (
    <>
      <TableContainer component={Paper} elevation={12}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledHeaderCell key="add">
                {currentUser.role === 'Admin' ? <AddUser onAddUser={handleAddUser} /> : ''}
              </StyledHeaderCell>
              {columns.map(column => {
                if (column === 'id') return null;
                return (
                  <StyledHeaderCell key={column}>
                    {column.toUpperCase().replace('_', " ")}
                  </StyledHeaderCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <StyledTableRow key={row.id}>
                <StyledBodyCell key="edit-cell">
                  <EditUser user={row} role={currentUser.role} onEditUser={handleEditUser} />
                  <IconButton
                    aria-label="delete"
                    color="error"
                    onClick={() => handleDelete(row.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </StyledBodyCell>
                <TableCell key="email">{row['email']}</TableCell>
                <TableCell key="first_name">{row['first_name']}</TableCell>
                <TableCell key="last_name">{row['last_name']}</TableCell>
                <TableCell key="role">{row['role']}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Toaster />
    </>
  )
}

UsersList.propTypes = {
  data: PropTypes.array
}

