import React from 'react';
import {TailSpin} from 'react-loader-spinner';
import {styled} from '@mui/material/styles';

const SpinnerWrapper = styled("div")({
  width: "100px",
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "200px",
  flexGrow: 1
})

export default function Spinner() {
  return (
    <SpinnerWrapper>
      <TailSpin arialLabel="loading-indicator" color="#53A551" />
    </SpinnerWrapper>)
}
