import React from 'react';
import {useParams} from 'react-router-dom';
import {oneProductAtom} from '../../state';
import Spinner from '../../components/Spinner';
import NotFound from '../NotFound';
import {Grid} from '@mui/material';
import Title from '../../components/Title';
import {useFetchData} from '../../hooks';
import {getOne} from '../../services';
import ProductDetails from '../../components/ProductDetails/ProductDetails';
import SuppliersPrices from '../../components/SuppliersPrices/SuppliersPrices';
import Paper from '@mui/material/Paper';
import PeriodicCharts from '../../components/PeriodicCharts';
import DailyChart from '../../components/DailyChart';
import TransactionLogs from '../../components/TransactionLogs';

export default function Prices() {
  const {id} = useParams();

  const {isLoading, isError, fetchedData} = useFetchData(getOne, oneProductAtom, id);

  if (isLoading) return <Spinner />;
  if (isError) return <NotFound />;

  return (
    <Paper sx={{padding: 2, mb: 1}} elevation={12}>
      <Paper sx={{padding: 1, mb: 1}} elevation={6}>
        <Title>{fetchedData['name']} {fetchedData['sku']}</Title>
      </Paper>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={7}>
          <SuppliersPrices data={fetchedData} />
        </Grid>
        <Grid item xs={5}>
          <ProductDetails data={fetchedData} />
        </Grid>
        <Grid item xs={4}>
          <DailyChart data={fetchedData} />
        </Grid>
        <Grid item xs={8}>
          <PeriodicCharts id={id} data={fetchedData}/>
        </Grid>
        <Grid item xs={12}>
          <TransactionLogs id={id} />
        </Grid>
      </Grid>
    </Paper>
  )
}
