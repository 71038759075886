import {API_URL} from '../constants/railsAPIURL';
import axios from 'axios';

const baseUrl = `${API_URL}suppliers`;

export function getCompetitors() {
  return axios.get(`${baseUrl}`)
}

export function editCompetitor({active, id}) {
  let data = new FormData();
  data.append("supplier[active]", active);
  return axios.put(`${baseUrl}/${id}`, data)
}

export function editShippingPolicy({shipping_policy, id}) {
  let data = new FormData();
  data.append("supplier[shipping_policy]", shipping_policy);
  return axios.put(`${baseUrl}/${id}`, data)
}
