import React from 'react';
import {TableSortLabel} from '@mui/material';
import {useRecoilState, useRecoilValue} from 'recoil';
import {filterStateAtom, orderStateAtom, productsListAtom, rowsPerPageAtom, sortFieldAtom} from '../../state';
import {getAll} from '../../services';
import {getFilterData, setPlainData} from '../../helpers';
import {StyledHeaderCell} from '../../styleditems';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

export default function SortableCell({data, onLoading, shippingCost, competitors}) {
  const [order, setOrder] = useRecoilState(orderStateAtom);
  const [fetchedData, setFetchedData] = useRecoilState(productsListAtom);
  const [sortField, setSortField] = useRecoilState(sortFieldAtom);
  const rowsPerPage = useRecoilValue(rowsPerPageAtom);
  const filter = useRecoilValue(filterStateAtom);
  const filterData = getFilterData(filter);
  let sendOrderField = data.field;

  const handleClick = async () => {
    if (data.field === 'product_suppliers') {
      const {slug} = fetchedData.rows[0]['product_suppliers'].find(({slug}) => slug === data.slug)
      if (shippingCost) {
        sendOrderField = `product_suppliers.${slug}.shipping_cost`;
      } else {
        sendOrderField = `product_suppliers.${slug}.current_price`;
      }
    }
    if (data.field.includes('product_detail')) {
      sendOrderField = data.field.replace('product_detail', 'product_details');
    }
    try {
      onLoading(true);
      const response = await getAll({
        perPage: rowsPerPage,
        direction: order === 'asc' ? 'desc' : 'asc',
        sort: sendOrderField,
        filteredData: filterData
      });
      const orderData = response.data;
      await setOrder(order === 'asc' ? 'desc' : 'asc');
      await setSortField(sendOrderField);
      await setFetchedData(setPlainData(orderData.data));
      onLoading(false);
    } catch (e) {
      console.log(e);
      onLoading(false);
    }
  }

  if (data.field === 'sku') {
    return (<StyledHeaderCell style={{position: "sticky", left: 0, zIndex: "300", minWidth: "100px"}}>
      <TableSortLabel
        active={sortField === data.field}
        direction={order}
        onClick={handleClick}
      >
        {data.fieldName}
      </TableSortLabel>
    </StyledHeaderCell>)
  }
  if (data.field === 'name') {
    return (<StyledHeaderCell style={{
      position: "sticky", left: "132px", zIndex: "300", maxWidth: "150px", borderRight: "3px solid #A9A3A34D"
    }}>
      <TableSortLabel
        active={sortField === data.field}
        direction={order}
        onClick={handleClick}
      >
        {data.fieldName}
      </TableSortLabel>
    </StyledHeaderCell>)
  }

  let headerColumn;
  if (!shippingCost && (data.fieldName === 'Home Depot' || data.fieldName === 'Lowes' || data.fieldName === 'Menards' || data.fieldName === 'Max Warehouse' || data.fieldName === 'Painters Solutions' || data.fieldName === 'Integrity Supply')) {
    const {shipping_policy} = competitors.find(item => item.title === data.fieldName) || {}
    headerColumn = <div>
      <div>{shippingCost ? shippingCost : data.fieldName}</div>
      <Tooltip title={shipping_policy ? shipping_policy : 'No shipping policy'}>
        <div style={{fontSize: "0.7rem", fontWeight: "400"}}>Hover for Shipping Policy</div>
      </Tooltip>
    </div>
  } else {
    headerColumn = shippingCost ? shippingCost : data.fieldName
  }


  return (<StyledHeaderCell>
    <TableSortLabel
      active={sortField === data.field}
      direction={order}
      onClick={handleClick}
    >
      {headerColumn}
    </TableSortLabel>
  </StyledHeaderCell>)
}

SortableCell.propTypes = {
  data: PropTypes.object
}
