export const suppliersTitles = [
  {"name": "Amazon 1", "slug": "amazon"},
  {"name": "Amazon 2", "slug": "amazon-1"},
  {"name": "Amazon 3", "slug": "amazon-2"},
  {"name": "Amazon 4", "slug": "amazon-3"},
  {"name": "Amazon 5", "slug": "amazon-4"},
  {"name": "Walmart 1", "slug": "walmart"},
  {"name": "Walmart 2", "slug": "walmart-1"},
  {"name": "Walmart 3", "slug": "walmart-2"},
  {"name": "Walmart 4", "slug": "walmart-3"},
  {"name": "Walmart 5", "slug": "walmart-4"},
  {"name": "Home Depot", "slug": "home-depot"},
  {"name": "Lowes", "slug": "lowes"},
  {"name": "Menards", "slug": "menards"},
  {"name": "Max Warehouse", "slug": "max-warehouse"},
  {"name": "Painters Solutions", "slug": "painters-solutions"},
  {"name": "Integrity Supply", "slug": "integrity-supply"},
  {"name": "Paint Supply", "slug": "paint-supply"}
]
