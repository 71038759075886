import React from 'react';

export default function NotFound({error}) {
  return (
    <div>
      <h1>404 - Not Found!</h1>
      <h2>{error}</h2>
    </div>
  )
}
