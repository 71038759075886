import React, {useState} from 'react';
import {useOpenCloseDialog} from '../../hooks';
import {toast} from 'react-hot-toast';
import Button from '@mui/material/Button';
import {Dialog, DialogActions, DialogContent, DialogContentText, TextField} from '@mui/material';
import CustomDialogTitle from '../CustomDialogTitle';
import {StyledButton} from '../../styleditems';
import {forgotPassword} from '../../services';

export default function ForgotPassword() {
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [currentValue, setCurrentValue] = useState("");

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
  }

  const handleSave = async () => {
    try {
      if (currentValue.length === 0) {
        toast.error("Please, enter email");
        return null;
      }
      await forgotPassword(currentValue)
      setCurrentValue('');
      handleClose();
    } catch (error) {
      handleClose();
      toast.error(`Such Email has not found`);
    }
  }

  return (
    <>
      <Button sx={{color: "#53A551"}} onClick={handleOpen}>
        Forgot password?
      </Button>
      <Dialog open={isOpen} onClose={handleClose} >
        <CustomDialogTitle>Send Email</CustomDialogTitle>
        <DialogContent sx={{minWidth: "400px"}}>
          <DialogContentText>
            Please, enter your email
          </DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            id="email"
            label="email"
            type="email"
            fullWidth
            variant="standard"
            value={currentValue}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleClose}>Cancel</StyledButton>
          <StyledButton onClick={handleSave}>Send</StyledButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
