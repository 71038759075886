import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, Select, TextField} from '@mui/material';
import {useOpenCloseDialog} from '../../hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import CustomDialogTitle from '../CustomDialogTitle';
import {newUserFields} from '../../constants/newUserFields';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import {editUserValidationSchema} from '../../constants/editUserValidationSchema';


export default function EditUser({user, role, onEditUser, text}) {
  const {isOpen, handleOpen, handleClose} = useOpenCloseDialog();
  const [selectValue, setSelectValue] = useState(user['role'].toLowerCase());

  const defaultFieldValues = {
    email: user['email'],
    first_name: user['first_name'],
    last_name: user['last_name'],
    password: '',
    confirm_password: ''
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors, isSubmitSuccessful}
  } = useForm({
    resolver: yupResolver(editUserValidationSchema),
    defaultValues: defaultFieldValues
  });

  const handleChange = (event) => {
    setSelectValue(event.target.value);
  }

  const onSubmit = newUser => {
    handleClose();
    onEditUser({...newUser, role: selectValue, id: user.id});
  };

  const onClose = () => {
    reset(defaultFieldValues);
    handleClose();
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultFieldValues);
    }
  }, [isSubmitSuccessful]);

  const userFields = newUserFields.map(({field, label, type}) => (
    <Grid item xs={12} sm={12} key={field}>
      <TextField
        id={field}
        name={field}
        label={label}
        type={type}
        defaultValue=""
        fullWidth
        margin="dense"
        {...register(field)}
        error={!!errors[field]}
      />
      <Typography variant="inherit" color="textSecondary">
        {errors[field]?.message}
      </Typography>
    </Grid>
  ))

  return (
    <>
      <IconButton
        aria-label="edit"
        onClick={handleOpen}
        color="success"
      >
        <EditIcon />
        <Typography sx={{pl: 1, color: "#303D4E"}}>{text}</Typography>
      </IconButton>
      <Dialog open={isOpen}>
        <CustomDialogTitle>Edit User</CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {userFields}
            {role === 'Admin' &&
              <Grid item xs={12} sm={12} key="role">
                <FormControl fullWidth>
                  <InputLabel id="simple-select-required-label">Role</InputLabel>
                  <Select
                    labelId="simple-select-required-label"
                    id="simple-select-required"
                    label="Role"
                    value={selectValue}
                    onChange={handleChange}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>Save</Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

EditUser.propTypes = {
  user: PropTypes.object,
  role: PropTypes.string,
  onEditUser: PropTypes.func
}
