import {styled} from '@mui/styles';
import TableCell from '@mui/material/TableCell';

export const StyledHeaderCell = styled(TableCell)({
  fontWeight: "700",
  fontSize: "1rem",
  lineHeight: "1.2",
  paddingBottom: "5px",
  whiteSpace: "wrap",
  minWidth: "120px"
})
