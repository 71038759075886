import React from 'react';
import {priceStatus} from '../../constants/priceStatus';
import {ListItem, ListItemIcon, ListItemText, Stack} from '@mui/material';
import {Circle} from '@mui/icons-material';

export default function PriceStatusLegend() {
  const legend = Object.keys(priceStatus).map(item => (
    <ListItem key={item} sx={{pr: 1, pl: 1, width: "unset"}}>
      <ListItemIcon sx={{color: priceStatus[item], minWidth: "unset"}}>
        <Circle />
      </ListItemIcon>
      <ListItemText secondary={item} disableTypography sx={{color: priceStatus[item], fontSize: "small"}} />
    </ListItem>
  ))

  return (
    <Stack direction="row" sx={{mt: 2}}>
      {legend}
    </Stack>
  );
}
