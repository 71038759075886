import {API_URL} from '../constants/railsAPIURL';
import axios from 'axios';

const baseUrl = `${API_URL}layout_settings`;

export function getLayouts() {
  return axios.get(`${baseUrl}`)
}

export function uploadLayouts(currentValue, uploadData) {
  let sendData = new FormData();
  const currentLayout = {"id": currentValue, "data":uploadData};
  sendData.append('data', JSON.stringify(currentLayout));
  return axios.post(`${baseUrl}`, sendData);
}

export function putLayout(id, name, uploadData) {
  let sendData = new FormData();
  const currentLayout = {"id": name, "data":uploadData};
  sendData.append('data', JSON.stringify(currentLayout));
  return axios.put(`${baseUrl}/${id}`, sendData);
}

export function deleteLayout(id) {
  return axios.delete(`${baseUrl}/${id}`);
}