import {API_URL} from '../constants/railsAPIURL';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';

const baseUrl = `${API_URL}products`;

export function getAll({pageNum = 1, perPage = 25, direction = 'asc', sort = 'id', filteredData}) {
  return axios.get(`${baseUrl}`,
    {
      params:
        {
          filter: filteredData,
          page: pageNum,
          per_page: perPage,
          direction: direction,
          sort: sort
        },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    });
}

export function getOne(id) {
  return axios.get(`${baseUrl}/${id}`)
}

export function addSKU({sku, file}) {
  let data = new FormData();
  if (sku) {
    data.append("product[sku]", sku);
  }
  if (file) {
    data.append("product[file]", file)
  }
  return axios.post(`${baseUrl}`, data);
}

export function getPriceHistory(
                                  id,
                                  start_date = moment('2022-03-01').format('MM/DD/YYYY'),
                                  end_date = moment().format('MM/DD/YYYY')
                                ) {
  return axios.get(`${baseUrl}/${id}/price_history?start_date=${start_date}&end_date=${end_date}`);
}

export function downloadCSV(filter) {
  return axios.get(`${baseUrl}?commit=Download`,
    {
      params:
        {
          filter: filter,
        },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    });
}

export function editStatus(id, status) {
  let data = new FormData();
  data.append("product[status]", status);
  return axios.put(`${baseUrl}/${id}`, data);
}

export function editCompetitorURL({id, product_id, url, active, status, url_verified}) {
  let data = new FormData();
  data.append("product_supplier[url]", url);
  // data.append("product_supplier[active]", active);
  data.append("product_supplier[status]", status);
  data.append("product_supplier[url_verified]", url_verified);
  return axios.put(`${API_URL}/products/${product_id}/product_suppliers/${id}`, data)
}

export function getTransactionsLogs(id) {
  return axios.get(`${API_URL}/products/${id}/transaction_logs`);
}
