export const suppliersPricesHeaders = [
  {
    field: 'supplier_name',
    headerName: 'Competitor',
  },
  {
    field: 'current_price',
    headerName: 'Price',
  },
  {
    field: 'shipping_cost',
    headerName: 'Shipping Cost',
  },
  {
    field: 'last_checked',
    headerName: 'Last Checked',
  },
  {
    field: 'updated_at',
    headerName: 'Last Changed'
  },
  {
    field: 'url_verified',
    headerName: 'Url Verified'
  }
]
