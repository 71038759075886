import React from 'react';
import {useFetchData} from '../../hooks';
import {editCompetitor, getCompetitors} from '../../services/competitors.service';
import {competitorsListAtom} from '../../state';
import Spinner from '../../components/Spinner';
import NotFound from '../NotFound';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import {Checkbox, TableBody} from '@mui/material';
import {makeUpperCase} from '../../helpers';
import {suppliersHeaders} from '../../constants/suppliersHeaders';
import {useSetRecoilState} from 'recoil';
import {StyledBodyCell, StyledHeaderCell, StyledTableRow} from '../../styleditems';
import Link from '@mui/material/Link';
import EditShippingPolicy from '../../components/EditShippingPolicy';

export default function Suppliers() {
  const {isLoading, isError, fetchedData} = useFetchData(getCompetitors, competitorsListAtom);
  const setStatus = useSetRecoilState(competitorsListAtom);
  const {role} = JSON.parse(localStorage.getItem('user'));

  if (isLoading) return <Spinner />;
  if (isError) return <NotFound />;

  const handleChange = async (event) => {
    const competitor = fetchedData.find(({title}) => title === event.target.name);
    const newCompetitor = {...competitor, active: !competitor.active};
    try {
      const {data} = await editCompetitor(newCompetitor);
      setStatus(prevState => prevState.map((item) => item.id === data.data.id ? data.data : item));
    } catch (error) {
      console.log(error);
    }
  };

  const columns = role === "Admin" ?
    [...suppliersHeaders.map(({field, headerName}) =>
      <StyledHeaderCell key={field}>
        {makeUpperCase(headerName)}
      </StyledHeaderCell>
    ), <StyledHeaderCell key="action" />] :
    suppliersHeaders.map(({field, headerName}) =>
      <StyledHeaderCell key={field}>
        {makeUpperCase(headerName)}
      </StyledHeaderCell>
    );

  const rows = [...fetchedData].sort((a, b) => a.id - b.id).map((row) => (
    <StyledTableRow
      key={row.id}
    >
      {[...suppliersHeaders.map(({field}) => {
          if (field === 'active') {
            return (<StyledBodyCell key={row['active']}>
              {row.title !== 'Paint Supply' && <Checkbox
                sx={{color: "#53A551", "&.Mui-checked": {color: "#53A551"}}}
                checked={row['active']}
                onChange={handleChange}
                inputProps={{'aria-label': 'controlled'}}
                name={row.title}
              />}
            </StyledBodyCell>)
          } else if (field === 'title') {
            return (
              <StyledBodyCell sx={{paddingTop: '5px', paddingBottom: '5px'}} key={field}>
                <Link href={row['url'] ? row['url'] : null} target="_blank">{row[field]}</Link>
              </StyledBodyCell>
            )
          } else {
            return (
              <StyledBodyCell sx={{paddingTop: '5px', paddingBottom: '5px'}} key={field}>
                {row[field] === null || typeof row[field] === 'object' ? '' : row[field]}
              </StyledBodyCell>
            )
          }
        }
      ), role === "Admin" && <StyledBodyCell>
        <EditShippingPolicy id={row.id} />
      </StyledBodyCell>]}
    </StyledTableRow>
  ));

  return (
    <TableContainer component={Paper} elevation={12}>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
