import React, {useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {
  logsAtom, logsFilterStateAtom, logsrowsPerPageAtom,
} from '../../state';
import {getLogs} from '../../services';
import Spinner from '../Spinner';
import NotFound from '../../pages/NotFound';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import {useFetchData} from '../../hooks';
import {StyledBodyCell, StyledHeaderCell, StyledTableRow} from '../../styleditems';
import moment from 'moment';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Paper, TableBody} from '@mui/material';
import {NavLink} from 'react-router-dom';
import LogsRowsPerPageSelect from './LogsRowsPerPageSelect';
import LogsPagination from './LogsPagination';
import LogsProductsToolbar from './LogsProductsToolbar';
import {getFilterData} from '../../helpers';

export default function TestComponent() {
  const {isError, isLoading} = useFetchData(getLogs, logsAtom);
  const [data, setData] = useRecoilState(logsAtom);
  const {rows, total_pages, current_page} = data;
  const [rowsPerPage, setRowsPerPage] = useRecoilState(logsrowsPerPageAtom)
  const [loading, setLoading] = useState(false);
  const filter = useRecoilValue(logsFilterStateAtom);
  const filterData = getFilterData(filter);

  if (isLoading || loading) return <Spinner />;
  if (isError) return <NotFound />;

  const headers = Object.keys(rows[0]).map((item) => {
    if (item === 'product_id') return null;
    return (
      <StyledHeaderCell key={item}>
        {item.replace('_', ' ').toUpperCase()}
      </StyledHeaderCell>
    )
  });

  const tableRows = rows.map(row => (
    <StyledTableRow key={row.id}>
      {Object.keys(rows[0]).map((item) => {
        if (item === 'product_id') return null;
        if (item === 'product_sku') {
          return (
            <StyledBodyCell key={item}>
              <NavLink
                to={{pathname: `/products/${row['product_id']}`}}
                target="_blank"
                style={{color: "#53A551"}}
              >
                {row[item]}
              </NavLink>
            </StyledBodyCell>
          )
        }
        if (item === 'created_at') {
          return (
            <StyledBodyCell key={item}>
              {moment(row[item]).format('MM/DD/YYYY, hh:mm:ss')}
            </StyledBodyCell>
          )
        }
        return (
          <StyledBodyCell key={item}>
            {row[item]}
          </StyledBodyCell>
        )
      })}
    </StyledTableRow>
  ))

  const handleChange = async (event, value) => {
    try {
      setLoading(true);
      const response = await getLogs({
        pageNum: value,
        perPage: rowsPerPage,
        filteredData: filterData
      });
      const {data} = response.data;
      setData(prevState => {
          const {rows} = data;
          return {...prevState, rows: rows, current_page: data['current_page']}
        }
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const handleRowsPerPageChange = async (event) => {
    try {
      setLoading(true);
      const response = await getLogs({
        pageNum: 1,
        perPage: event.target.value,
        filteredData: filterData
      });
      const {data} = response.data;
      setData(prevState => {
          const {rows} = data;
          return {...prevState, rows: rows, current_page: data['current_page'], total_pages: data['total_pages']}
        }
      );
      setRowsPerPage(event.target.value);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  };


  return (
    <Paper elevation={6}>
      <LogsProductsToolbar />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{display: "flex", justifyContent: "end", marginRight: "20px"}}>
        <LogsRowsPerPageSelect rowsPerPage={rowsPerPage} handleRowsPerPageChange={handleRowsPerPageChange} />
        <LogsPagination total_pages={total_pages} current_page={current_page} handleChange={handleChange} />
      </div>
    </Paper>
  );
}
