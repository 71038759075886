import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {menuRoutes} from '../../constants/menuRoutes';
import Logo from './Logo';
import CustomMenuItem from './CustomMenuItem';

export default function DesktopView({currentUser}) {
  const menu = menuRoutes.map(({route, text}) => {
      if ((currentUser.role !== "Admin" && route === "/users")||(currentUser.role !== "Admin" && route === "/competitors")) {
        return null;
      }
      return <CustomMenuItem key={text} text={text} route={route} alignment="center" />
    }
  )

  return (
    <>
      <Typography
        component="div"
        sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}
      >
        <Logo />
      </Typography>
      <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
        {menu}
      </Box>
    </>
  );
}
