import {API_URL} from '../constants/railsAPIURL';
import axios from 'axios';
import qs from 'qs';

const baseUrl = `${API_URL}transaction_logs`;

export function getLogs({pageNum = 1, perPage = 25, filteredData}) {
  return axios.get(`${baseUrl}`,
    {
      params:
        {
          filter: filteredData,
          page: pageNum,
          per_page: perPage
        },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    });
}
