import React from 'react';
import {StyledBodyCell} from '../../../styleditems';
import DetailViewButton from '../DetailViewButton';

export default function ActionsCell({column, row}) {
  return (
    <StyledBodyCell key={column.field}>
      <DetailViewButton id={row.id} status={row.status} />
    </StyledBodyCell>)
}
