import React from 'react';
import {MenuItem} from '@mui/material';
import EditUser from '../EditUser/EditUser';
import {toast} from 'react-hot-toast';
import {editOneUser} from '../../services';
import {useSetRecoilState} from 'recoil';
import {authAtom, usersAtom} from '../../state';

export default function UserProfile({user, onCloseUserMenu}) {
  const setUsers = useSetRecoilState(usersAtom);
  const setCurrentUser = useSetRecoilState(authAtom);

  const handleCustomOpen = () => {
    onCloseUserMenu();
  }

  const handleEditUser = async (newUser) => {
    try {
      if (newUser.password.length > 0 && newUser.password.length < 6) {
        toast.error("Password must be at least 6 characters");
        return null;
      }
      const {data} = await editOneUser(newUser);
      setUsers(prevState => prevState.map(item => item.id === data.data.id ? data.data : item));
      if (user.id === data.data.id) {
        localStorage.setItem("user", JSON.stringify(data.data));
        setCurrentUser(data.data);
      }
      toast.success("Edit user data success!")
    } catch (e) {
      toast.error("Some error")
    }
  }

  return (
    <MenuItem onClick={handleCustomOpen}>
      <EditUser
        user={user}
        role={user.role}
        onEditUser={handleEditUser}
        text="Account"
      />
    </MenuItem>
  );
}
