import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import {styled} from '@mui/styles';
import {authAtom} from '../../state';
import {useRecoilValue} from 'recoil';
import MobileView from './MobileView';
import DesktopView from './DesktopView';
import MenuAvatar from './MenuAvatar';
import SearchPanel from '../SearchPanel';

const NabBar = styled(AppBar)({
  position: "relative",
  backgroundColor: "#fff"
})

export default function HeadBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const currentUser = useRecoilValue(authAtom);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  }

  const stringAvatar = (name) => {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <NabBar>
      <Container maxWidth="100vw">
        <Toolbar disableGutters sx={{maxHeight: "90px"}}>
          <MobileView
            handleOpenNavMenu={handleOpenNavMenu}
            currentUser={currentUser}
            anchorElNav={anchorElNav}
            handleCloseNavMenu={handleCloseNavMenu}
          />
          <DesktopView currentUser={currentUser}/>
          <SearchPanel />
          <MenuAvatar
            handleOpenUserMenu={handleOpenUserMenu}
            stringAvatar={stringAvatar}
            currentUser={currentUser}
            anchorElUser={anchorElUser}
            handleCloseUserMenu={handleCloseUserMenu}
          />
        </Toolbar>
      </Container>
    </NabBar>
  )
};

