import React from 'react';
import {Grid, ListItem, ListItemText} from '@mui/material';
import DeleteButton from '../DeleteButton';
import {deleteLayout} from '../../services/layout.service';
import {useSetLayouts} from '../../hooks';
import PropTypes from 'prop-types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {filterStateAtom, productsListAtom} from '../../state';
import {getFilterData, setPlainData} from '../../helpers';
import {getAll} from '../../services';

export default function LoadLayoutItem({id, row, onClose, setBadge}) {
  const {setColumnHeaders, setFilterState, setColumnsView, setLayouts} = useSetLayouts();
  const {productsHeader, currentFilterState, currentColumnsView} = row.data;
  const userFilterState = useRecoilValue(filterStateAtom);
  const setFilteredData = useSetRecoilState(productsListAtom);

  const handleListItemClick = async () => {
    try {
      if (window.confirm("If you load the selected layout, your current layout (filter, columns, etc.) will be changed. Would you like to continue?")) {
        setBadge(row.id);
        if (userFilterState !== currentFilterState) {
          const filterData = getFilterData(currentFilterState);
          const response = await getAll({filteredData: filterData});
          const {data} = response.data;
          setFilteredData(_ => {
              const {rows} = setPlainData(data);
              return {rows: rows, current_page: data['current_page'], total_pages: data['total_pages']}
            }
          );
        }
        setColumnHeaders(productsHeader);
        setFilterState(currentFilterState);
        setColumnsView(currentColumnsView);

        onClose(true);
      } else {
        return null;
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleDelete = async () => {
    if (!window.confirm("Are you sure to delete this item?")) return null;
    try {
      await deleteLayout(id);
      setLayouts(prevState => prevState.filter(item => item.id !== id));
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={9}>
        <ListItem button onClick={handleListItemClick} key={id}>
          <ListItemText primary={row.id} />
        </ListItem>
      </Grid>
      <Grid item xs={2} sx={{mt: 0.5}}>
        <DeleteButton onClick={handleDelete} />
      </Grid>
    </Grid>
  )
}

LoadLayoutItem.propTypes = {
  id: PropTypes.number,
  row: PropTypes.object,
  onClose: PropTypes.func,
  setBadge: PropTypes.func
}
