export const productFilterColumns = {
  "ID": "id",
  "SKU": "sku",
  "Product Title": "products.name",
  "UPC": "upc_code",
  "Part #": "part_number",
  "Manufacturer": "manufacturer",
  "Brand": "brands.title",
  "Model #": "model_number",
  "PaintSupply.com Price": "ps_price",
  "PS – Cheapest, $": "min_price_diff_doll",
  "PS vs Cheapest, %": "min_price_diff_perc",
  /*"Amazon Buy Box": "product_suppliers.amazon.total_price",
  "Amazon Product Price": "product_suppliers.amazon.current_price",
  "Amazon Shipping Fee": "product_suppliers.amazon.shipping_cost",
  "Amazon 2": "product_suppliers.amazon-1.total_price",
  "Amazon 2 Product Price": "product_suppliers.amazon-1.current_price",
  "Amazon 2 Shipping Fee": "product_suppliers.amazon-1.shipping_cost",
  "Amazon 3": "product_suppliers.amazon-2.total_price",
  "Amazon 3 Product Price": "product_suppliers.amazon-2.current_price",
  "Amazon 3 Shipping Fee": "product_suppliers.amazon-2.shipping_cost",
  "Amazon 4": "product_suppliers.amazon-3.total_price",
  "Amazon 4 Product Price": "product_suppliers.amazon-3.current_price",
  "Amazon 4 Shipping Fee": "product_suppliers.amazon-3.shipping_cost",
  "Amazon 5": "product_suppliers.amazon-4.total_price ",
  "Amazon 5 Product Price": "product_suppliers.amazon-4.current_price ",
  "Amazon 5 Shipping Fee": "product_suppliers.amazon-4.shipping-cost ",
  "Walmart Buy Box": "product_suppliers.walmart.total_price ",
  "Walmart Buy Box Product Price": "product_suppliers.walmart.current_price ",
  "Walmart Shipping Fee": "product_suppliers.walmart.shipping_cost",
  "Walmart 2": "product_suppliers.walmart-1.total_price",
  "Walmart 2 Product Price": "product_suppliers.walmart-1.current_price",
  "Walmart 2 Shipping Fee": "product_suppliers.walmart-1.shipping_cost",
  "Walmart 3": "product_suppliers.walmart-2.total_price",
  "Walmart 3 Product Price": "product_suppliers.walmart-2.current_price",
  "Walmart 3 Shipping Fee": "product_suppliers.walmart-2.shipping_cost",
  "Walmart 4": "product_suppliers.walmart-3.total_price",
  "Walmart 4 Product Price": "product_suppliers.walmart-3.current_price",
  "Walmart 4 Shipping Fee": "product_suppliers.walmart-3.shipping_cost",
  "Walmart 5": "product_suppliers.walmart-4.total_price",
  "Walmart 5 Product Price": "product_suppliers.walmart-4.current_price",
  "Walmart 5 Shipping Fee": "product_suppliers.walmart-4.shipping_cost",
  "Home Depot": "product_suppliers.home-depot.total_price",
  "Home Depot Product Price": "product_suppliers.home-depot.current_price",
  "Home Depot Shipping Fee": "product_suppliers.home-depot.shipping_cost",
  "Lowes": "product_suppliers.lowes.total_price",
  "Lowes Product Price": "product_suppliers.lowes.current_price",
  "Lowes Shipping Fee": "product_suppliers.lowes.shipping_cost",
  "Menards": "product_suppliers.menards.total_price",
  "Menards Product Price": "product_suppliers.menards.current_price",
  "Menards Shipping Fee": "product_suppliers.menards.shipping_cost",
  "Max Warehouse": "product_suppliers.max-warehouse.total_price",
  "Max Warehouse Product Price": "product_suppliers.max-warehouse.current_price",
  "Max Warehouse Shipping Fee": "product_suppliers.max-warehouse.shipping_cost",
  "Painters Solutions": "product_suppliers.painters-solutions.total_price",
  "Painters Solutions Product Price": "product_suppliers.painters-solutions.current_price",
  "Painters Solutions Shipping Fee": "product_suppliers.painters-solutions.shipping_cost",
  "Integrity Supply": "product_suppliers.integrity-supply.total_price",
  "Integrity Supply Product Price": "product_suppliers.integrity-supply.current_price",
  "Integrity Supply Shipping Fee": "product_suppliers.integrity-supply.shipping_cost",*/
  "Div 11 Units Sold": "product_details.div_11_units_sold",
  "Div 12 Units Sold": "product_details.div_12_units_sold",
  "Div 12 Orders": "product_details.div_12_orders",
  "Paint Supply Product Page": "pss_url",
  "Vendor #": "vendor_number",
  "Pack UPC Code": "pack_upc_code",
  "ASIN": "asin_number",
  "Walmart ID": "walmart_id",
  "PDP Family ID": "pdp_family_id",
  "Stock Locs": "product_details.stock_locs",
  "UOM": "product_details.uom",
  "UOM QTY": "product_details.uom_qty",
  "Attr Name 1": "product_details.attr_name_1",
  "Attr Value 1": "product_details.attr_value_1",
  "Sequence 1": "product_details.sequence_1",
  "Attr Name 2": "product_details.attr_name_2",
  "Attr Value 2": "product_details.attr_value_2",
  "Sequence 2": "product_details.sequence_2",
  "Discount QTY 1": "product_details.discount_qty_1",
  "Discount PCT 1": "product_details.discount_pct_1",
  "Discount Price 1": "product_details.discount_price_1",
  "Discount QTY 2": "product_details.discount_qty_2",
  "Discount PCT 2": "product_details.discount_pct_2",
  "Discount Price 2": "product_details.discount_price_2",
  "Taxonomy": "tags",
  "Competitor url status": "products_suppliers.status"
}
