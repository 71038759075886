import React from 'react';
import {styled} from '@mui/material/styles';
import {Pagination} from '@mui/material';
import PropTypes from 'prop-types';

const CustomPagination = styled(Pagination)({
  marginTop: "20px",
  marginBottom: "10px",
  ".MuiPagination-ul": {
    justifyContent: "end"
  },
  ".MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
    color: "#53A551",
    border: "1px solid #53A551"
  }
})

export default function ProductsListPagination({total_pages, current_page, handleChange}) {
  return (
    <CustomPagination
      count={total_pages}
      page={current_page}
      variant="outlined"
      shape="rounded"
      showFirstButton
      showLastButton
      onChange={handleChange}
    />
  );
}

ProductsListPagination.propTypes = {
  total_pages: PropTypes.number,
  current_page: PropTypes.number,
  handleChange: PropTypes.func
}
