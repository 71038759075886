import React from 'react';
import Link from '@mui/material/Link';
import LinkIcon from '@mui/icons-material/Link';
import {StyledBodyCell} from '../../../styleditems';

export default function PssUrlCell({column, row}) {
  return (
    <StyledBodyCell key={column.field}>
      {row['pss_url'] ? <Link href={row['pss_url']} target="_blank"><LinkIcon /></Link> : ''}
    </StyledBodyCell>
  );
}
